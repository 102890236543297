import { Alert, Button, Col, DatePicker, Row, Spin, Table, UploadFile } from "antd";
import { useEffect, useState } from "react";
import CollapseCard from "../../components/Template/Card";
import { useFetchActivityHistory } from "../../hooks/activity/useFetchActivityHistory";
import { useFetchComments } from "../../hooks/activity/useFetchComments";
import { usePostComment } from "../../hooks/activity/usePostComment";
import { useFetchFilesByActivity } from "../../hooks/files/useFetchFilesByActivity";
import { useFetchModelsByActivity } from "../../hooks/files/useFetchModelsByActivity";
import { Activity } from "../../models/Activity";
import api from "../../services/Api";
import AssignContractCard from "../AssignContract/contractCard";
import CommentCard from "../CommentCard";
import dayjs from "dayjs";
import { MeasureFileListCard } from "../MeasureFileListCard";
import "./activity.scss";
import {
  ActivityCriteriaCard,
  ActivitySituationCard,
  HistoryCard,
  InformationCard,
  MeasureCard,
  ModelListCard,
  PreviousActivityCard,
  ResponsibleCard,
  ValidationRulesCard,
} from "./cards";
import { toastAlert } from "../../utils/toastAlert";
import { usePostDate } from "../../hooks/measure/usePostDate";

import MeasurementPartialsActions from "../MeasurementPartialsActions/MeasurementPartialsActions";
import { partialColumns } from "./utils/partialColumns";

function ActivityView({
  hasList,
  setRefreshActivity,
  isPopup,
  activity,
}: {
  hasList?: boolean;
  isPopup?: boolean;
  activity?: Activity;
  setRefreshActivity: (date: Date) => void;
}) {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [modelList, setModelList] = useState<UploadFile[]>([]);

  const [showContractCard, setShowContractCard] = useState(true);
  const [criteria, setCriteria] = useState<string | undefined>("");
  const [contractResponsible, setContractResponsible] = useState<{ name: string; email: string } | undefined>();

  const [refreshFileList, setRefreshFileList] = useState(new Date());
  const [refreshModelList, setRefreshModelList] = useState(new Date());

  const [comment, setComment] = useState<string>("");
  const [statusId, setStatusId] = useState<number | undefined>(activity?.status_id);

  const [roles, setRoles] = useState<string[]>([]);
  const enableSubmit = roles.some((item) => ["Administrador", "Owner"].includes(item));

  const [refreshHistory, setRefreshHistory] = useState<Date>(new Date());
  const [activityIsInitiated, setActivityIsInitiated] = useState<boolean>(activity?.status_id !== 1);

  const { history } = useFetchActivityHistory({
    activity_id: activity?.id,
    refresh: refreshHistory,
  });

  useEffect(() => {
    api.get(`/v1/user/roles`).then((response) => {
      if (response.status === 200) {
        const data = response.data;
        setRoles(data.roles);
      }
    });
  }, []);

  const { postComment, isFetching, refresh } = usePostComment();

  const { comments } = useFetchComments({
    activity_id: activity?.id,
    refresh,
  });

  const { isLoadingModels } = useFetchModelsByActivity({
    activity_id: activity?.id,
    setModelList,
    refreshModelList,
  });

  const { isLoadingFiles } = useFetchFilesByActivity({
    activity_id: activity?.id,
    setFileList,
    refreshFileList,
  });

  const handleSendComment = async () => {
    const formData = {
      message: comment,
      activity_id: activity?.id,
    };

    if (!isFetching) await postComment({ formData });
  };

  useEffect(() => {
    setComment("");
  }, [isFetching]);

  const contractAfterSubmit = (name?: string, email?: string) => {
    setRefreshActivity(new Date());
    if (name && email) setContractResponsible({ name, email });
    setShowContractCard(false);
  };
  const shouldRenderComponent = fileList.some((obj: any) => !!obj.refuse_justification);

  return (
    <>
      <Row gutter={16} className="page-row">
        <Col xs={24} lg={11}>
          <InformationCard
            setRefreshActivity={setRefreshActivity}
            status_id={statusId}
            showContractCard={showContractCard}
            criteria={criteria}
            isPopup={isPopup}
            activity={activity}
          />

          {activity?.responsible || !!contractResponsible ? <ResponsibleCard responsible={activity?.responsible || contractResponsible} /> : null}
          <ModelListCard
            canCollapse
            modelList={modelList}
            isLoading={isLoadingModels}
            refreshList={() => {
              setRefreshModelList(new Date());
              setRefreshHistory(new Date());
            }}
          />

          <HistoryCard history={history} isPopup={isPopup} />
        </Col>

        <Col xs={24} lg={13} className="mb-2">
          <ActivitySituationCard
            showContractCard={showContractCard}
            isPopup={isPopup}
            activity={activity}
            status_id={statusId}
            setStatus={setStatusId}
            setActivityIsInitiated={setActivityIsInitiated}
            roles={roles}
            setRefresh={(date) => {
              setRefreshActivity(new Date());
              setRefreshHistory(new Date());
            }}
            fileList={fileList}
          />

          {activity && (!!activity.contract || !showContractCard) ? (
            <>
              <ActivityCriteriaCard
                activity={activity}
                criteria={activity.measurement_criteria}
                setRefreshActivity={setRefreshActivity}
                updateCriteria={setCriteria}
                roles={roles}
              />

              <ValidationRulesCard isPopup={isPopup} activity={activity}    roles={roles}/>
            </>
          ) : null}

          <MeasureCard
            isPopup={isPopup}
            activity={activity}
            showContractCard={showContractCard}
            activityIsInitiated={activityIsInitiated}
            setRefreshFileList={setRefreshFileList}
            fileList={fileList}
            roles={roles}
          />

          {activity && !activity.contract && showContractCard ? (
            <CollapseCard canCollapse title={`Contrato`} className="activity_creation-contract">
              {roles.length === 0 ? (
                <Spin />
              ) : !enableSubmit ? (
                <Alert message="Seu usuário não tem permissão para criar contratos." type="warning" className="w-100" />
              ) : (
                <>
                  <div className="activity_creation-contract_message">Atividade não tem contrato, associe um já existente a ela.</div>
                  <AssignContractCard afterSubmit={contractAfterSubmit} />
                </>
              )}
            </CollapseCard>
          ) : null}

          {activity && !!activity.parent_activity ? <PreviousActivityCard activity={activity} /> : null}

          <CollapseCard
            title={`Solicitações de avanços físicos`}
            style={{
              marginBottom: isPopup ? 0 : 20,
              width: "100%",
            }}
          >
            <Table
              size="small"
              style={{ overflow: "auto" }}
              scroll={{
                x: "700px",
              }}
              dataSource={activity?.partials_measurements?.map((obj: any) => ({
                ...obj,
                actions: (
                  <div
                    style={{
                      display: "flex",
                      gap: 8,
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >

                    <MeasurementPartialsActions
                      measurement_partial={obj}
                      refreshList={() => {
                        setRefreshActivity(new Date());
                        setRefreshHistory(new Date());
                      }}
                      actual_percentage={0}
                      activity={activity}
                    />
                  </div>
                ),
              }))}
              columns={partialColumns}
            />
          </CollapseCard>
          <MeasureFileListCard
            fileList={fileList}
            canCollapse
            isLoading={isLoadingFiles}
            refreshList={() => {
              setRefreshFileList(new Date());
              setRefreshHistory(new Date());
            }}
            roles={roles}
            showButton={(activity?.contract || !showContractCard) && activityIsInitiated}
            hideActions={false}
          />
          {shouldRenderComponent && (
            <CollapseCard canCollapse={false} title="Motivo da Glosa de evidência" style={{ marginBottom: 20 }}>
              <div className="container-justifications">
                {fileList.map((item: any) => {
                  if (item.refuse_justification) {
                    return (
                      <div>
                        <span>
                          <b>{item.show_name}</b>: {item.refuse_justification}{" "}
                        </span>
                      </div>
                    );
                  }
                })}
              </div>
            </CollapseCard>
          )}

          {/* <FileListCard
            canCollapse
            cardTitle="Lista de Arquivos"
            fileList={fileList}
            isPopup={isPopup}
            isLoading={isLoadingFiles}
            refreshList={() => {
              setRefreshFileList(new Date());
              setRefreshHistory(new Date());
            }}
          /> */}

          <CommentCard
            canCollapse
            commentList={comments}
            inputComment={comment}
            setInputComment={setComment}
            handleSendComment={handleSendComment}
            uploading={isFetching}
          />
        </Col>
      </Row>
    </>
  );
}

export default ActivityView;
