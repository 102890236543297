export const years = [
  { value: "", label: "Todos" },
  { value: 2023, label: 2023 },
  { value: 2024, label: 2024 },
  { value: 2025, label: 2025 },
];
export const yearsWithoutAll = [
  { value: 2021, label: 2021 },
  { value: 2022, label: 2022 },
  { value: 2023, label: 2023 },
  { value: 2024, label: 2024 },
  { value: 2025, label: 2025 },
];

export const statusItems = [
  { value: 1, label: "Pendente" },
  { value: 2, label: "Aguardando" },
  { value: 3, label: "Recusado" },
  { value: 4, label: "Validado" },
];
