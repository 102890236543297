import { Badge } from "antd";

export const tabs = [
  {
    label: <Badge status="default" text="Pendentes" />,
    key: "pending",
    children: <></>,
  },
  // {
  //   label: <Badge status="warning" text="Em andamento" />,
  //   key: "2",
  //   children: <></>,
  // },
  {
    label: <Badge status="success" text="Validadas" />,
    key: "approved",
    children: <></>,
  },
  {
    label: <Badge status="error" text="Recusadas" />,
    key: "refused",
    children: <></>,
  },
];

export const tabs2 = [
  {
    label: <Badge status="default" text="Evidências" />,
    key: "evidences",
    children: <></>,
  },

  {
    label: <Badge status="success" text="Medições" />,
    key: "measurements",
    children: <></>,
  },
];
