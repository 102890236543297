import { Popconfirm, Tag, Tooltip } from "antd";
import PopConfirmIcon from "../../../../components/PopConfirmIcon/PopConfirmIcon";
import { IoClose } from "react-icons/io5";
import RefuseModal from "../../../../components/ModalRefuse/ModalRefuse";
import { FaCheck } from "react-icons/fa";
import { useEffect, useState } from "react";
import api from "../../../../config/axios";
import { MeasurementBulletinActivity } from "../../../../models/MeasurementBulletinActivity";
import { useApproveActivity } from "../../../../hooks/bulletinActivities/useApproveActivity";
import { useReproveActivity } from "../../../../hooks/bulletinActivities/useReproveActivity";

type Props = {
  data: MeasurementBulletinActivity;
  refetch: () => void;
  roles: string;
};
export default function BulletinActions({ data, refetch, roles }: Props) {
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const { approveActivity, isApproving } = useApproveActivity({ refetch });
  const { reproveActivity, isReproving } = useReproveActivity({ refetch });

  const handleRefuse = (id: any, message: string) => {
    reproveActivity(id, message);
  };

  const canEdit = roles?.includes("Subowner_auditor") || roles?.includes("Administrador") || roles?.includes("Owner") || roles?.includes("Subowner");

  if (+data.audit_status !== 1 && !!data.audit_status) {
    return (
      <div style={{ width: "100%", display: "flex" }}>
        {data.audit_status === 4 ? (
          <Tag color={"green"} key={data.audit_status}>
            Validado
          </Tag>
        ) : (
          <Tag color={"volcano"} key={data.audit_status}>
            Recusado
          </Tag>
        )}
      </div>
    );
  }
  return (
    <div style={{ width: "100%", display: "flex", gap: "24px", alignItems: "center" }}>
      {canEdit && (
        <>
          <PopConfirmIcon
            title="Validar"
            description="Tem certeza que deseja validar esse registro?"
            disabled={isApproving}
            onConfirm={() => approveActivity(data.bulletin_measurement_id)}
            icon={<FaCheck size={18} className={`file-table-list-actions${!!isApproving ? "-disabled" : ""}`} />}
          />
          <div>
            <Popconfirm
              disabled={isReproving}
              title={"Reprovar"}
              description={"Deseja mesmo reprovar esse registro?"}
              onConfirm={() => setOpenModal(true)}
              popupVisible={confirmIsOpen}
              onOpenChange={(open) => setConfirmIsOpen(open)}
              okText="Sim"
              cancelText="Não"
            >
              <Tooltip title={"Reprovar"}>
                <IoClose size={24} className={`file-table-list-actions${!!isReproving ? "-disabled" : ""}`} />
              </Tooltip>
            </Popconfirm>
          </div>
          <RefuseModal
            isModalOpen={openModal}
            handleClose={() => setOpenModal(!openModal)}
            handleRefuse={(message: string) => {
              handleRefuse(data.bulletin_measurement_id, message);
            }}
          />
        </>
      )}
    </div>
  );
}
