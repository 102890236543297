import { TableColumnsType, Tag } from "antd";
import { formatCurrency } from "../../../utils/formatCurrency";
import { TextCropToltip } from "../../../components/TextCropToltip";

import { formatPercentage } from "../../../utils/formatPercentage";

export const columns: TableColumnsType<any> = [
  // {
  //   title: "Detalhes da Atividade",
  //   key: "activity_details",
  //   fixed: 'left',
  //   render: (text, record) => {
  //     return (
  //       <div>
  //         <div>
  //           <small>
  //             {record.execution_date
  //               ? new Date(record.execution_date).toLocaleDateString("pt-BR", {
  //                   year: "numeric",
  //                   month: "2-digit",
  //                   day: "2-digit",
  //                 })
  //               : "-"}
  //             <br />
  //           </small>
  //           <Tag color="purple">{record.activity?.project_information?.name || "-"}</Tag>
  //           <Tag color="purple">{record.activity?.percentual_group?.name || "-"}</Tag>
  //           <Tag color="purple">{record.activity?.percentual_group?.category || "-"}</Tag>
  //           <Tag color="purple">{record.activity?.name || "-"}</Tag>
  //         </div>
  //         <div style={{ marginTop: "8px" }}>
  //           <TextCropToltip text={record.activity?.iwp?.description || "-"} max_quantity={100} />
  //         </div>
  //       </div>
  //     );
  //   },
  //   width: 450,
  // },
  {
    title: "Código",
    dataIndex: ["activity", "code"],
    key: "code",
    width: 250,
    fixed: 'left',
    render: (code, record) => (
      <a href={`/activity/${record.activity?.id}`} rel="noopener noreferrer">
        {code}
      </a>
    ),
  },
  {
    title: "Data de Execução",
    key: "execution_date",
    dataIndex: "execution_date",   
    render: (execution_date) =>
      execution_date
        ? new Date(execution_date).toLocaleDateString("pt-BR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
        : "-",
    width: 150,
  },
  // {
  //   title: "Projeto",
  //   key: "project",
  //   dataIndex: ["activity", "project_information", "name"],
  //   render: (project) => <Tag color="purple">{project || "-"}</Tag>,
  //   width: 200,
  // },
  // {
  //   title: "Grupo",
  //   key: "group",
  //   dataIndex: ["activity", "percentual_group", "name"],
  //   render: (group) => <Tag color="purple">{group || "-"}</Tag>,
  //   width: 200,
  // },
  {
    title: "Etapa",
    key: "group_category",
    dataIndex: ["activity", "percentual_group", "category"],
    render: (category) => <Tag color="purple">{category || "-"}</Tag>,
    width: 200,
  },
  {
    title: "Step de medição",
    key: "activity_name",
    dataIndex: ["activity", "name"],
    render: (activityName) => <Tag color="purple">{activityName || "-"}</Tag>,
    width: 200,
  },
  {
    title: "Descrição",
    key: "iwp_description",
    dataIndex: ["activity", "iwp", "description"],
    render: (description) => (
      <TextCropToltip text={description || "-"} max_quantity={100} />
    ),
    width: 250,
  },

  {
    title: "Status",    
    dataIndex: ["status"],
    key: "status",
    width: 150,
  },
  {
    title: "Justificativa",
    dataIndex: ["reason_for_refusal"],
    key: "reason_for_refusal",
    render: (text) => <div>{text || "-"}</div>,
    width: 150,
  },
  {
    title: "Valor Solicitado",
    dataIndex: ["requestedamount"],
    key: "activity_cost",
    render: (requestedamount) => <div>{requestedamount ? formatCurrency(requestedamount) : "-"}</div>,
    width: 150,
  },
  {
    title: "Porcentagem acumulada da medição",
    dataIndex: ["percentage"],
    key: "percentage",
    render: (percentage) => (
      <div>{percentage !== null ? formatPercentage(percentage) : "-"}</div>
    ),
    width: 200,
  },
  {
    title: "Porcentagem solicitada",
    dataIndex: ["initial_requested_percentage"],
    key: "initial_requested_percentage",
    render: (requestedPercentage) => (
      <div>{requestedPercentage ? formatPercentage(requestedPercentage) : "-"}</div>
    ),
    width: 200,
  },
  {
    title: "Porcentagem validada",
    dataIndex: "percentage",
    key: "approved_percentage",
    render: (percentage, record) => (
      <div>
        {record.measure_refused_by === null || record.status_id === 4
          ? formatPercentage(percentage)
          : "-"}
      </div>
    ),
    width: 200,
  },
  {
    title: "Porcentagem Glosada",
    dataIndex: "glossed",
    key: "old_percentage_value",
    render: (glossed) =>
      glossed.glossedPercentage !== undefined
        ? formatPercentage(glossed.glossedPercentage)
        : "-",
    width: 200,
  },
  {
    title: "Valor Glosado",
    dataIndex: "glossed",
    key: "refused_percentage_cost",
    render: (glossed) => (
      <div>{glossed.glossedValue ? formatCurrency(glossed.glossedValue) : "-"}</div>
    ),
    width: 150,
  },
  {
    title: "Valor validado",
    dataIndex: "glossed",
    key: "value_aproved",
    render: (glossed) => (
      <div style={{ color: "#60a902", fontWeight: "bold" }}>
        {glossed.approvedValue ? formatCurrency(glossed.approvedValue) : "-"}
      </div>
    ),
    width: 150,
  },
  //Augusto pediu para retirar
  // {
  //   title: "Porcentagem para Pagamento",
  //   dataIndex: "glossed",
  //   key: "percentage_for_payment",
  //   render: (glossed) => (
  //     <div style={{ color: "#60a902", fontWeight: "bold" }}>
  //       {glossed.porcentagemPagamento ? formatPercentage(glossed.porcentagemPagamento)  : "-"}
  //     </div>
  //   ),
  //   width: 150,
  // },
  {
    title: "",
    dataIndex: "actions",
    key: "actions",
    width: 160,
  },
];

export default columns;
