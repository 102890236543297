import { useState, useEffect } from "react";
import api from "../../config/axios";
import { toastAlert } from "../../utils/toastAlert";

interface MeasurementValidation {
  id: number;
  name: string;
  status: string;
  created_at: string;
}

export const useFetchMeasurementValidations = () => {
  const [validations, setValidations] = useState<MeasurementValidation[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchValidations = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get("/v1/measure/all-validation");
      setValidations(response.data || []);
    } catch (error: any) {
      const msg = error.response?.data?.message || "Erro ao buscar validações de medição.";
      setError(msg);
      toastAlert({
        type: "error",
        message: msg,
        title: "Erro",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchValidations();
  }, []);

  return { validations, loading, error, fetchValidations };
};
