export const calculateAppliedMeasurement = (
  percentComplete: string | null,
  dynamicMeasurementItems: any[]
) => {
  if (!percentComplete || !dynamicMeasurementItems || dynamicMeasurementItems.length === 0) {    
    return "";
  }
  
  const percentCompleteValue = parseFloat(percentComplete);
  // Encontra o item correspondente na faixa de valores
  const matchedItem = dynamicMeasurementItems.find(
    (item) =>
      percentCompleteValue >= parseFloat(item.initial_percentage) &&
      percentCompleteValue <= parseFloat(item.end_percentage)
  );
  let appliedMeasurement = matchedItem ? matchedItem.percentage : "";
  return appliedMeasurement;
};
