import { useState } from "react";
import { Select, Table,  Checkbox, Modal, Button } from "antd";
import { RotateRightOutlined } from "@ant-design/icons";
import "./SettingsPage.scss";
import { useFetchContracts } from "../../hooks/contracts/useFetchContracts";
import api from "../../config/axios";
import { toastAlert } from "../../utils/toastAlert";

interface Iwp {
    id: string;
    name: string;
    unique: boolean;
}

export default function IwpFlagSettings() {
    const { contracts } = useFetchContracts({ current: 1 });
    const [selectedContract, setSelectedContract] = useState<number | null>(null);
    const [loading, setLoading] = useState(false);
    const [iwps, setIwps] = useState<Iwp[]>([]);
    const [selectedIwpIds, setSelectedIwpIds] = useState<string[]>([]);
    const [bulkUpdateValue, setBulkUpdateValue] = useState<boolean | null>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleContractChange = async (contractId: number) => {
        setLoading(true);
        try {
            const response = await api.get(`v1/iwps/search-iwps`, {
                params: { contract_id: contractId },
            });
            setIwps(response.data.data || []);
        } catch (error) {
            console.error("Erro ao buscar IWPs:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleConfirmUnique = async (ids: string[], newValue: boolean) => {
        if (!ids.length) return;

        try {
            await api.put(`v1/iwps`, {
                iwps: ids,
                unique: newValue,
            });

            setIwps((prevIwps) =>
                prevIwps.map((iwp) =>
                    ids.includes(iwp.id) ? { ...iwp, unique: newValue } : iwp
                )
            );

            toastAlert({
                type: "success",
                message: `Os IWPs selecionados foram atualizados com sucesso.`,
                title: "Atualizado com Sucesso!",
            });
        } catch (error) {
            toastAlert({
                type: "error",
                message: "Ocorreu um erro ao tentar atualizar os IWPs.",
                title: "Erro!",
            });
        } finally {
            setSelectedIwpIds([]);
            setBulkUpdateValue(null);
            setIsModalVisible(false);
        }
    };

    const columns = [
        {
            title: <Checkbox onChange={(e) => setSelectedIwpIds(e.target.checked ? iwps.map(iwp => iwp.id) : [])} checked={selectedIwpIds.length === iwps.length} />, 
            dataIndex: "select",
            key: "select",
            render: (_: any, record: Iwp) => (
                <Checkbox
                    checked={selectedIwpIds.includes(record.id)}
                    onChange={(e) => {
                        setSelectedIwpIds((prev) =>
                            e.target.checked ? [...prev, record.id] : prev.filter(id => id !== record.id)
                        );
                    }}
                />
            ),
        },
        {
            title: "Nome Iwp",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Permitido sub Atividades?",
            dataIndex: "unique",
            key: "unique",
            render: (unique: boolean) => (unique ? "Não" : "Sim"),
        }
    ];

    return (
        <div>
            <div className="iwp-flag-settings-container">
                <Select
                    value={selectedContract}
                    placeholder="Selecione o contrato"
                    className="contract-select"
                    onChange={(value) => {
                        setSelectedContract(value);
                        handleContractChange(value);
                    }}
                    options={contracts?.map((item: any) => ({
                        value: item.id,
                        label: item.description,
                    }))}
                />
                {selectedIwpIds.length > 0 && (
                    <Button type="primary" onClick={() => setIsModalVisible(true)}>
                        Alterar Sub Atividades
                    </Button>
                )}
            </div>
            {iwps.length > 0 && (
                <Table
                    dataSource={iwps}
                    columns={columns}
                    rowKey="id"
                    loading={loading}
                    bordered
                    pagination={false}
                />
            )}
            <Modal
                title={<p style={{ fontSize: '28px' }}>Atualizar IWPs</p>}
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                width={600}
                footer={null}
            >
                <div className="bulk-update-container">
                    <span className="bulk-update-text">Deseja permitir sub atividades para os IWPs selecionados?</span>
                    <Select
                        value={bulkUpdateValue}
                        placeholder="Selecione uma opção"
                        className="bulk-update-select"
                        onChange={(value) => {
                            setBulkUpdateValue(value);
                            handleConfirmUnique(selectedIwpIds, value);
                        }}
                        options={[
                            { value: false, label: "Sim" },
                            { value: true, label: "Não" },
                        ]}
                    />
                </div>
            </Modal>
        </div>
    );
}
