import { CloudUploadOutlined } from "@ant-design/icons";
import { Alert, Avatar, Button, Col, Input, Row, Select, Spin, Table, TableColumnsType, Timeline, Typography, Upload, UploadFile } from "antd";
import React, { ReactNode, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { statusList } from "../../constants/status";
import { useUploadActivityFile } from "../../hooks/files/useUploadActivityFile";
import { useSubmitDescription } from "../../hooks/measure/useSubmitDescription";
import api from "../../services/Api";
import { formatCurrency } from "../../utils/formatCurrency";
import { toastAlert } from "../../utils/toastAlert";
import { FileCard } from "../FileCard";
import { ExpandedPreviousActivity } from "../PreviousActivities/expandedRows";
import CollapseCard from "../Template/Card";
import "./activity.scss";
import { FaEdit } from "react-icons/fa";
import { formatPercentage } from "../../utils/formatPercentage";
import EditPercentage from "./components/EditPercentage/EditPercentage";
import ModalPartialMesurement from "./components/ModalPartialMesurement/ModalPartialMeaurement";
import { usePostMeasurementPartial } from "../../hooks/measure/usePostMeasurementPartial";
import { measurementsColumns } from "./utils/partialColumns";
import { useFetchValidationActivity } from "../../hooks/activity/useFetchValidationActivity";

export interface DataType {
  key: React.Key;
  name: string;
}

const { TextArea } = Input;

export function InformationCard({
  status_id,
  showContractCard,
  criteria,
  isPopup,
  activity,
  isMeasureScreen,
  setRefreshActivity,
}: {
  status_id?: number | undefined;
  showContractCard?: boolean;
  criteria: string | undefined;
  isPopup?: boolean;
  activity?: any;
  isMeasureScreen?: boolean;
  setRefreshActivity: (date: Date) => void;
}) {
  const currentStatusName = statusList[statusList.findIndex((item) => item.value === status_id)]?.label;
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <CollapseCard canCollapse title={"Informações"} style={{ marginBottom: isPopup ? 0 : 20 }}>
      {isMeasureScreen && (
        <Button
          size="small"
          onClick={() => navigate("/activity/" + activity?.id)}
          style={{ marginLeft: "85%" }}
          className="activity_creation-submit"
          type="primary"
        >
          Ir para atividade
        </Button>
      )}
      <InfoData name="Descrição" value={activity?.information?.joined_wp_description} />

      <div className="container-cols-activity">
        <div className="col-infodata">
          <div className="container-info-activity">
            <InfoData name="Tipo" value={activity?.type} />
            <InfoData name="Código" value={activity?.code} />
            <InfoData name="Código WP" value={activity?.wp_code} />
            <InfoData name="Subdisciplina" value={activity?.sub_discipline && activity?.sub_discipline.name} />
          </div>

          <div className="container-info-activity">
            <InfoData name="Horas estimadas" value={activity?.estimate_hours} />
          </div>
          <div className="container-info-activity">
            <div className="container-edit-percentage">
              <InfoData
                name="Porcentagem completa"
                col={12}
                value={!!activity?.percent_complete ? formatPercentage(activity?.percent_complete) : undefined}
              />
              <FaEdit size={24} className="icon-edit" onClick={() => setIsModalOpen(!isModalOpen)} />
              <EditPercentage
                activity_id={activity?.id}
                handleCancel={() => setIsModalOpen(!isModalOpen)}
                isModalOpen={isModalOpen}
                setRefreshActivity={setRefreshActivity}
              />
            </div>
          </div>

          <div className="container-info-activity">
            <InfoData name="Data inicial da linha base" value={activity?.baseline_start_date} />
            <InfoData name="Data final da linha base" value={activity?.baseline_completion_date} />
          </div>
          <div className="container-info-activity">
            {/* <InfoData name="Data de execução" value={activity?.activity_date ? formatDate(activity?.activity_date) : "-"} /> */}
            <InfoData name="Início real" value={activity?.actual_start} />
            <InfoData name="Conclusão real" value={activity?.actual_completion} />
          </div>
          {activity && !activity.contract && showContractCard ? <InfoData name="Situação" value={currentStatusName} /> : <></>}
        </div>
        <div className="col-infodata">
          <div className="container-info-activity">
            <InfoData name="Etapa" value={activity?.information.percentual_group_category} />
            <InfoData name="Step de Medição" value={activity?.name} />
            <InfoData name="Medição" value={activity?.measure && activity?.measure.description} />
            <InfoData name="Critérios de medição" value={activity?.measurement_criteria || criteria} />
            <InfoData name="Flutuação total" value={activity?.total_float} />
          </div>

          <div className="container-info-activity">
            <InfoData name="Custo" value={activity?.cost ? ` ${formatCurrency(activity?.cost)}` : "-"} />
            <InfoData name="Peso" value={activity?.percentual ? `${activity?.percentual}%` : "-"} />
            <InfoData name="Unidade" value={activity?.unit?.abbreviation} />
            <InfoData name="Quantidade" value={activity?.quantity} />
          </div>
          <div className="container-info-activity">
            <InfoData
              name="Data de criação"
              value={new Date(activity?.created_at).toLocaleDateString("pt-BR", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            />
            <InfoData
              name="Última atualização"
              value={new Date(activity?.updated_at).toLocaleDateString("pt-BR", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            />
            <InfoData name="Deletado em" value={activity?.deleted_at} />
          </div>
        </div>
      </div>
    </CollapseCard>
  );
}

export function ResponsibleCard({ responsible }: { responsible?: { name: string; email: string; url?: string } }) {
  const initialletters =
    (responsible?.name?.split(" ")[0]?.charAt(0)?.toUpperCase() || "") + (responsible?.name?.split(" ")[1]?.charAt(0)?.toUpperCase() || "");

  return (
    <CollapseCard canCollapse title="Responsável" style={{ marginBottom: 20 }}>
      <div className="activity_creation-profile-card">
        <div className="activity_creation-profile-card_avatar">
          {!!responsible?.url ? (
            <Avatar src={responsible?.url} className="avatarImage" />
          ) : (
            <Avatar className="activity_creation-profile-card_avatar_text">{initialletters}</Avatar>
          )}
        </div>
        <div className="activity_creation-profile-card_input-list">
          <b>{responsible?.name}</b>
          <span>{responsible?.email}</span>
        </div>
      </div>
    </CollapseCard>
  );
}

export function ActivityCriteriaCard({
  isPopup,
  activity,
  updateCriteria,
  setRefreshActivity,
  criteria,
  roles,
}: {
  isPopup?: boolean;
  activity?: any;
  criteria?: any;
  setRefreshActivity: (Date: Date) => void;
  updateCriteria: React.Dispatch<React.SetStateAction<string | undefined>>;
  roles: string[];
}) {
  const canEditCriteria = roles.includes("Subowner_control") || roles.includes("Administrador") || roles.includes("Hired");

  const { activity_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [currentCriteria, setCurrentCriteria] = useState(criteria);
  const [editCriteria, setEditCriteria] = useState(!criteria);

  const enableChangeCriteria = roles.some((item) => ["Administrador", "Owner", "Subowner", "Subowner_control"].includes(item));

  const handleChangeCriteria = () => {
    if (!currentCriteria) {
      toastAlert({
        type: "warning",
        message: `Preencha o campo de critério para salvar.`,
        title: "Critério não preenchido",
      });
      return;
    }
    setLoading(true);
    api
      .patch(`/v1/activity/criteria/${activity_id}`, {
        measurement_criteria: currentCriteria,
      })
      .then((response) => {
        updateCriteria(currentCriteria);
        setEditCriteria(false);
        setRefreshActivity(new Date());
        toastAlert({
          type: "success",
          message: `O critério da atividade foi registrado com sucesso`,
          title: "Critério atualizado",
        });
      })
      .catch((error) => {
        toastAlert({
          type: "error",
          message: `Não foi possível registrar o critério da atividade.`,
          title: "Critério não atualizado",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };




  return (
    <CollapseCard
      canCollapse
      title="Critério da medição"
      style={{
        marginBottom: isPopup ? 0 : 20,
      }}
    >
      {roles?.length > 0 ? (
        !enableChangeCriteria ? (
          <Alert message="Você não tem permissão para editar esse campo" type="warning" />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              gap: 8,
            }}
          >
            <TextArea
              id="measurement_criteria"
              name="measurement_criteria"
              placeholder="Digite os critérios de medição"
              value={currentCriteria}
              onChange={(e) => setCurrentCriteria(e.target.value)}
              disabled={!editCriteria}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "4px",
              }}
            >
              {canEditCriteria && !!criteria && (
                <Button type="primary" onClick={() => setEditCriteria(true)} disabled={loading || !canEditCriteria}>
                  Editar
                </Button>
              )}
              <Button type="primary" onClick={handleChangeCriteria} disabled={loading || !canEditCriteria}>
                Salvar
              </Button>
            </div>
          </div>
        )
      ) : (
        <Spin />
      )}
    </CollapseCard>
  );
}

export function ActivitySituationCard({
  showContractCard,
  isPopup,
  activity,
  setActivityIsInitiated,
  roles,
  setRefresh,
}: {
  showContractCard: boolean;
  isPopup?: boolean;
  activity?: any;
  status_id: number | undefined;
  setStatus: React.Dispatch<React.SetStateAction<number | undefined>>;
  setActivityIsInitiated: React.Dispatch<React.SetStateAction<boolean>>;
  roles: string[];
  setRefresh: (date: Date) => void;
  fileList: UploadFile<any>[];
}) {
  const { activity_id } = useParams();
  const hasContract = (activity && activity.contract) || !showContractCard;
  const [isOpen, setIsOpen] = useState(false);


  const enableChangeStatus = true;




  const handleChangeStatus = (newStatusId: number) => {

    api
      .patch(`/v1/activity/${activity.id}`, {
        status_id: newStatusId,
      })
      .then((response) => {
        if (response.status === 200) {
          setActivityIsInitiated(response.data.status_id !== 1);
          setRefresh(new Date());
        }
      })
      .catch((error) => {
        // Tratamento de erro
      })
  };

  let statusAtividade = '';
  let newStatusId = 0;
  let isEnabled = false;
  const percentComplete = parseFloat(activity.percent_complete);
  const ultimaMedicao = activity.partials_measurements.length > 0 ? activity.partials_measurements[0] : null;
  // Define statusAtividade e newStatusId com base em percent_complete
  if (percentComplete === 0) {
    statusAtividade = 'Não Iniciada';
    newStatusId = 1;
  } else if (percentComplete === 100 && ultimaMedicao && ultimaMedicao.status_id === 4) {
    statusAtividade = 'Concluída';
    newStatusId = 4;
    isEnabled = true;
  } else {
    statusAtividade = 'Em andamento';
    newStatusId = 2;
  }
  // UseEffect para evitar o loop infinito
  useEffect(() => {
    if (activity.status_id !== newStatusId) {
      handleChangeStatus(newStatusId);
    }
  }, [activity.status_id, newStatusId]);
  const { postMeasurementPartial, isFetching, refresh } = usePostMeasurementPartial();


  return (
    <CollapseCard
      canCollapse
      title={`Atividade: ${statusAtividade}`}
      style={{
        marginBottom: isPopup ? 0 : 20,
      }}
    >
      {roles?.length > 0 ? (
        !enableChangeStatus ? (
          <Alert message="Você não tem permissão para editar esse campo." type="warning" />
        ) : hasContract ? (
          <div className="container-actions-activity">
            <div>
              <div>
                <Button type="primary" onClick={() => setIsOpen(!isOpen)} disabled={isEnabled}>
                  Solicitar medição
                </Button>
              </div>
            </div>
            <ModalPartialMesurement
              handleClose={() => {
                setIsOpen(!isOpen);
              }}
              submit={async (value: { value: number; date: string }) => {
                await postMeasurementPartial({ activity_id: activity_id, percentage: value.value, date: value.date });
                setRefresh(new Date());
                setIsOpen(!isOpen);
              }}
              isOpen={isOpen}
              activity={activity}
            />
          </div>
        ) : (
          <Alert message="Crie um contrato para poder alterar a situação da atividade." type="warning" />
        )
      ) : (
        <Spin />
      )}
    </CollapseCard>
  );
}

export function HistoryCard({ history, isPopup }: { history?: any[] | undefined; isPopup?: boolean }) {
  return (
    <CollapseCard canCollapse title="Histórico" style={{ marginBottom: isPopup ? 0 : 20 }}>
      <Row
        style={{
          display: "flex",
          gap: 8,
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Timeline
          mode="left"
          reverse
          items={history?.map((item) => ({
            label: `${new Date(item.created_at).toLocaleDateString("pt-BR")} ${new Date(item.created_at).toLocaleTimeString("pt-BR", {
              hour: "2-digit",
              minute: "2-digit",
            })}`,
            children: item.message,
          }))}
        />
      </Row>
    </CollapseCard>
  );
}

export function MeasureCard({
  isPopup,
  activity,
  showContractCard,
  activityIsInitiated,
  setRefreshFileList,
  fileList,
  roles,
}: {
  isPopup?: boolean;
  activity?: any;
  showContractCard: boolean;
  activityIsInitiated: boolean;
  setRefreshFileList: React.Dispatch<React.SetStateAction<Date>>;
  fileList: UploadFile<any>[];
  roles: string[];
}) {
  const navigate = useNavigate();

  const [description, setDescription] = useState("");
  const [showErrors, setShowErrors] = useState(false);

  const enableSubmit = roles.some((item) =>
    ["Administrador", "Owner", "Subowner", "Subowner_control", "Subowner_quality", "Subowner_cost"].includes(item)
  );

  const hasContract = (activity && activity.contract) || !showContractCard;
  const hasRefusedMeasure = !!activity.measure && activity.measure.status_id === 3;

  const { handleSubmitAfterRefused, isFetching, handleSubmitDescription } = useSubmitDescription();
  const enableConcluded = roles.length > 0 && ["Administrador", "Owner", "Subowner", "Subowner_control"].includes(roles[0]);

  return (
    <CollapseCard canCollapse title="Medições" style={{ marginBottom: isPopup ? 0 : 20, flex: 1 }}>
      <Row
        style={{
          display: "flex",
          gap: activity && !!activity.measure ? 18 : 8,
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {activity && roles?.length > 0 ? (
          activity.measurements?.length > 0 ? (
            <>
              <div
                style={{
                  width: "100%",
                }}
              >
                <Table
                  size="small"
                  dataSource={activity?.measurements?.map((obj: any) => ({
                    ...obj,
                    actions: (
                      <div
                        style={{
                          display: "flex",
                          gap: 8,
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        <Button onClick={() => navigate("/measure/" + obj?.id)} className="activity_creation-submit" type="primary">
                          Acompanhar status da medição
                        </Button>
                      </div>
                    ),
                  }))}
                  columns={measurementsColumns}
                />
              </div>
            </>
          ) : hasContract && activityIsInitiated ? (
            <>
              {!enableSubmit ? (
                <Alert
                  message="Após análise e aprovação das evidências, Controle definirá se atividade pode ser considerada concluída e enviará para aprovação final"
                  type="warning"
                  className="w-100"
                />
              ) : null}
              {/*   <TextArea
                placeholder={`Descrição da ${hasRefusedMeasure ? "atualização da" : ""} medição`}
                rows={2}
                onChange={(event) => {
                  setDescription(event.target.value);
                }}
                disabled={!enableSubmit}
                style={{ marginTop: "8px" }}
              />

              <div className="activity_creation-actions">
                <Button
                  onClick={() => {
                    if (!enableConcluded) {
                      toastAlert({ type: "warning", title: "Espere!", message: "Esse usuário não pode concluir essa atividade" });
                    } else {
                      hasRefusedMeasure
                        ? handleSubmitAfterRefused({
                            activity,
                            description,
                            fileList,
                            setShowErrors,
                          })
                        : handleSubmitDescription({
                            activity,
                            description,
                            fileList,
                            setShowErrors,
                          });
                    }
                  }}
                  className="activity_creation-submit"
                  disabled={isFetching || !enableSubmit || fileList.length === 0}
                  loading={isFetching}
                  type="primary"
                >
                  Submeter {hasRefusedMeasure ? "novamente" : ""} para medição
                </Button>
              </div> */}
            </>
          ) : (
            <Alert
              message={
                !enableSubmit
                  ? "É permitido submeter somente as atividades que têm seu usuário como responsável."
                  : !hasContract
                    ? "Crie um contrato para poder submeter para medição."
                    : "Atividades não iniciadas não podem ser submetidas para medição."
              }
              type="warning"
              className="w-100"
            />
          )
        ) : (
          <Spin />
        )}
      </Row>
    </CollapseCard>
  );
}

export function PreviousActivityCard({ activity }: { activity?: any }) {
  const navigate = useNavigate();
  const dataSource = activity.parent_activity;
  const columns: TableColumnsType<DataType> = [
    { title: "Nome", dataIndex: "name", key: "name" },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (data) => <div className="align-right">{data}</div>,
    },
  ];

  const expandedRowRender = (data: any): ReactNode => {
    return <ExpandedPreviousActivity id={data.id} currentActivity={dataSource.parent_activity} />;
  };

  return (
    <CollapseCard canCollapse title={`Atividade predecessora`} style={{ marginBottom: 20 }}>
      <Table
        columns={columns}
        expandable={!!dataSource?.parent_activity ? { expandedRowRender } : undefined}
        dataSource={[
          {
            ...dataSource,
            actions: (
              <>
                <Button type="primary" onClick={() => navigate("/activity/" + dataSource.id)}>
                  Ir para atividade
                </Button>
              </>
            ),
          },
        ]}
        pagination={false}
        size="small"
      />
    </CollapseCard>
  );
}

export function ModelListCard({
  modelList,
  isLoading,
  canCollapse,
  refreshList,
}: {
  modelList: UploadFile[];
  isLoading?: boolean;
  canCollapse?: boolean;
  refreshList: VoidFunction;
}) {
  const { activity_id } = useParams();
  const { handleUpload, isUploading } = useUploadActivityFile();

  return (
    <CollapseCard canCollapse={canCollapse} title="Modelos" style={{ marginBottom: 20 }}>
      <Row gutter={16} wrap>
        {isLoading ? (
          <Spin />
        ) : (
          <>
            {modelList && modelList.length > 0 ? (
              modelList?.map((item: any) => <FileCard key={item.id} file={item} refreshList={refreshList} isModel />)
            ) : (
              <div className="pl-1">Atividade não tem modelos, clique no botão abaixo para fazer upload.</div>
            )}
            <Col xs={24}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Upload
                  beforeUpload={(file) => false}
                  showUploadList={false}
                  className="upload-cloud-btn"
                  onChange={(e) =>
                    handleUpload({
                      e,
                      activity_id,
                      setRefreshFileList: refreshList,
                      relation: "activityModels",
                    })
                  }
                  disabled={isUploading}
                  capture
                >
                  <Button icon={<CloudUploadOutlined />} loading={isUploading}>
                    {isUploading ? "Carregando modelo" : "Selecionar modelo..."}
                  </Button>
                </Upload>
              </div>
            </Col>
          </>
        )}
      </Row>
    </CollapseCard>
  );
}



export function ValidationRulesCard({ isPopup, activity, roles }: { isPopup?: boolean, activity: any, roles: string[] }) {
  console.log("activity_id", activity.id);
  
  const { validations, selectedValidations, isFetching, setSelectedValidations } = useFetchValidationActivity(activity.id);

  const validationOptions = validations.map((validation: any) => ({
    label: validation.title,
    value: validation.id,
  }));

  // Apenas Administradores ou Subowners podem editar
  const canEditValidations = roles.includes("Administrador") || roles.includes("Subowner_control");

  useEffect(() => {
    if (selectedValidations.length > 0) {
      setSelectedValidations(selectedValidations);
    }
  }, [selectedValidations]);

  const handleSave = () => {     
    api
      .post(`/v1/validation-evidence/${activity.id}/validation-evidences`, {
        validation_ids: selectedValidations,
      })
      .then((response) => {
        toastAlert({
          type: "success",
          message: `Regras de validação atualizadas com sucesso!`,
          title: "Regras salvas",
        });
      })
      .catch((error) => {
        toastAlert({
          type: "error",
          message: `Erro ao salvar regras de validações.`,
          title: "Erro",
        });
      });
  };

  return (
    <CollapseCard
      canCollapse
      title="Regras de Validações"
      style={{ marginBottom: isPopup ? 0 : 20 }}
    >
      {roles.length > 0 ? (
        !canEditValidations ? (
          <Alert message="Você não tem permissão para editar esse campo" type="warning" />
        ) : (
          <div>
            <Select
              mode="multiple"
              style={{ width: "70%" }}
              placeholder={isFetching ? "Carregando..." : "Selecione regras de validação"}
              options={validationOptions}
              value={selectedValidations}
              onChange={setSelectedValidations}
              loading={isFetching}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "4px",
              }}
            >
              <Button
                type="primary"
                onClick={handleSave}
                style={{ marginTop: 10 }}
                disabled={selectedValidations.length === 0}
              >
                Salvar
              </Button>
            </div>
          </div>
        )
      ) : (
        <Spin />
      )}
    </CollapseCard>
  );
}


function InfoData({ name, value, col }: { name: string; value?: string | number; col?: number }) {
  return (
    <div className="infoCol">
      <b>{name}: </b>
      <span>{value || "-"}</span>
    </div>
  );
}
