import { Button, Col, Row, Spin } from "antd";

import { useApproveRefuseRequisition } from "../../hooks/requisitions/useApproveRefuseRequisition";
import { useFetchWpRequisition } from "../../hooks/requisitions/useFetchWpRequisition";
import { useFetchWp } from "../../hooks/wps/useFetchWp";
import { Activities } from "./components/Activities";
import { InfoData } from "./components/InfoData";
import { Models } from "./components/Models";
import "./wpView.scss";

type Props = {
  handleCancel: () => void;
  selectedItem?: { id: number; type: string } | undefined;
  afterSubmit?: () => void;
};
const refresh = null;

export function WpView({ handleCancel, selectedItem, afterSubmit }: Props) {
  const { wpRequisition, isFetchingWpRequisition } = useFetchWpRequisition({
    wpRequisitionId: selectedItem?.id,
    refresh,
    loadingInitialValue: true,
  });
  const { wp: currentWp, isFetchingWp } = useFetchWp({
    wp_id: wpRequisition?.content?.id,
    refresh,
    loadingInitialValue: selectedItem?.type === "WP_EDIT",
  });

  const { approveOrRefuse, isFetching } = useApproveRefuseRequisition();
  const role = localStorage.getItem("user_role");
  const editValue = (currentValue: string, newValue: string) => {
    const showCurrentValue =
      !!currentWp &&
      currentValue !== newValue &&
      wpRequisition?.type === "WP_EDIT";
    return (
      <div>
        {showCurrentValue ? (
          <s className="wp-view-removed">{currentValue}</s>
        ) : null}{" "}
        <span className={showCurrentValue ? "wp-view-added" : ""}>
          {newValue || "-"}
        </span>
      </div>
    );
  };

  const handleAprove = () => {
    approveOrRefuse({ type: "approve", requisition_id: wpRequisition.id });
    handleCancel();
    afterSubmit && afterSubmit();
  };
  const handleRefuse = () => {
    approveOrRefuse({ type: "refuse", requisition_id: wpRequisition.id });
    handleCancel();
    afterSubmit && afterSubmit();
  };
  const isPending = !(
    !!wpRequisition?.approved_by || !!wpRequisition?.refused_by
  );

  return (
    <div className="form-view-container-WP">
      <div>
        {!isFetchingWpRequisition &&
        (selectedItem?.type === "WP_CREATE" || !isFetchingWp) ? (
          <Row gutter={24}>
            <Col xs={24} md={12}>
              <InfoData
                name="Código WP"
                value={editValue(currentWp?.name, wpRequisition?.content?.name)}
              />
              <InfoData
                name="Nome Alternativo"
                value={editValue(
                  currentWp?.alternative_name,
                  wpRequisition?.content?.alternative_name
                )}
              />
              <InfoData
                name="Disciplina"
                value={wpRequisition?.disciplineName}
              />
              <InfoData
                name="Subdisciplina"
                value={wpRequisition?.subDisciplineName}
              />

              <Activities
                wpRequisition={wpRequisition}
                selectedItemType={selectedItem?.type || ""}
              />
            </Col>
            <Col xs={24} md={12}>
              <InfoData
                name="Descrição"
                value={editValue(
                  currentWp?.description,
                  wpRequisition?.content?.description
                )}
              />
              <InfoData
                name="Status"
                value={editValue(
                  currentWp?.status_name,
                  wpRequisition?.statusName
                )}
              />

              <Models wpRequisition={wpRequisition} />

              <InfoData
                name="Justificativa"
                value={editValue(
                  currentWp?.justification,
                  wpRequisition?.content?.justification
                )}
              />
            </Col>
            {selectedItem?.type === "WP_EDIT" ? (
              <span style={{ paddingTop: 12 }}>
                * Itens em <span className="wp-view-added">verde</span> foram
                adicionados, em{" "}
                <span className="wp-view-removed">vermelho</span> removidos e em
                preto não foram alterados.
              </span>
            ) : null}
          </Row>
        ) : (
          <div className="wp-view-loading-container">
            <Spin />
          </div>
        )}
      </div>
      <div className="container-buttons-WP-add">
        <Button onClick={handleCancel}>Cancelar</Button>
        {!(role || "").includes("Subowner") && isPending && (
          <>
            <Button danger loading={isFetching} onClick={handleRefuse}>
              Rejeitar
            </Button>
            <Button
              htmlType="submit"
              loading={isFetching}
              type="primary"
              onClick={() => handleAprove()}
            >
              Validar  Requisição
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
