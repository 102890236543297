import { CloudUploadOutlined } from "@ant-design/icons";
import { Button, Spin, Table, UploadFile } from "antd";
import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { MeasureFileListActions } from "../MeasureFileListActions";
import CollapseCard from "../Template/Card";
import "./fileList.scss";
import { columns } from "./utils/columns";
import ModalEvidence from "./components/ModalEvidence/ModalEvidence";

export function MeasureFileListCard({
  fileList,
  refreshList,
  isLoading,
  canCollapse,
  roles,
  showButton,
  hideActions,
}: {
  fileList: UploadFile[];
  refreshList: VoidFunction;
  isLoading?: boolean;
  canCollapse?: boolean;
  roles: string[];
  showButton: boolean;
  hideActions: boolean;
}) {
  const { activity_id } = useParams();
  const [tableIsLoading, setTableIsLoading] = useState(isLoading);
  const [open, setIsOpen] = useState(false);

  const dataSource = fileList.map((file: any) => ({
    ...file,
    key: file.id,
    actions: (
      <>
       
          <MeasureFileListActions
            file={file}
            refreshList={refreshList}
            setTableIsLoading={setTableIsLoading}
            roles={roles}
            hideActions={hideActions}
          />
       
      </>
    ),
  }));

  useEffect(() => {
    console.log("Dados recebidos para a tabela:", fileList);
    setTableIsLoading(isLoading);
  }, [isLoading]);
 
  return (
    <>
      <CollapseCard canCollapse={canCollapse} title="Evidências" style={{ marginBottom: 20 }}>
        {tableIsLoading && dataSource.length === 0 ? (
          <Spin />
        ) : (
          <>
            <Table
              loading={tableIsLoading}
              size="small"
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              className="pb-2"
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "even-row" : "odd-row";
              }}
              style={{ overflow: "auto" }}
              scroll={{
                x: "800px",
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: fileList.length > 0 ? "space-between" : "flex-end",
                flexWrap: "wrap",
              }}
            >
              {fileList.length > 0 ? (
                <span>
                  * Datas em <span className="positive-text-color">verde</span> indicam aprovação da evidência, enquanto as{" "}
                  <span className="negative-text-color">vermelhas</span> indicam sua reprovação.
                </span>
              ) : null}

              <Button onClick={() => setIsOpen(true)} icon={<CloudUploadOutlined />}>
                Selecionar arquivos
              </Button>
              {open && <ModalEvidence activity_id={activity_id} refreshList={refreshList} isModalOpen={open} onClose={() => setIsOpen(false)} />}
            </div>
          </>
        )}
      </CollapseCard>
    </>
  );
}
