import { Tag } from "antd";

export default function returnStatusMeasurementPartials(status: number | string) {

  if (status === 4 || status === "approved") {
    return (
      <Tag color={"green"} key={status}>
        Validado
      </Tag>
    );
  } else if (status === 1 || status === "pending") {
    return (
      <Tag color={"geekblue"} key={status}>
        Pendente
      </Tag>
    );
  }else if (status === 5 || status === "approve-with-gloss") {
    return (
      <Tag color={"green"} key={status}>
        Validado com glosa
      </Tag>
    );
  }
   else if (status === 3 || status === "repproved") {
    return (
      <Tag color={"volcano"} key={status}>
        Recusado
      </Tag>
    );
  }
}
