import { TableColumnsType, Tag } from "antd";
import { formatCurrency } from "../../../utils/formatCurrency";
import { TextCropToltip } from "../../../components/TextCropToltip";
import formatDate from "../../../utils/formatDate";
import { formatPercentage } from "../../../utils/formatPercentage";

interface DataType {
  id: number;
  activity?: {
    id: number;
    code: string;
    name: string;
    quantity: string | null;
    cost: string | null;
    project_information?: {
      name: string;
    };
    unit?: {
      abbreviation: string;
    };
    contract?: {
      id: number;
    };
    cwp?: {
      description: string;
    } | null;
    iwp?: {
      description: string;
    } | null;
    percentual_group?: {
      name: string;
      category: string;
    } | null;
  };
  measurement?: {
    execution_date: Date | string | any;
    reason_for_refusal: string | null;
    measure_approved_by: string | null;
    measure_refused_by: string | null;
    measure_refused_date: string | null;
    status_id: number;
    percentage: number | null;
    old_percentage_value: number | null;
    initial_requested_percentage: number | null;
  };
  
  status?: any;
  glossed_percentage?: number | null; // Novo campo adicionado
  value_refused?: number | null;      // Novo campo adicionado
  description: string;
  activity_id: string;
  status_id: number;
  hired_id: number;
  activity_cost: any;
  quality_approved_by: string | null;
  quality_approved_date: string | null;
  control_approved_by: string | null;
  control_approved_date: string | null;
  measure_approved_by: string | null;
  measure_approved_date: string | null;
  quality_refused_by: string | null;
  quality_refused_date: string | null;
  control_refused_by: string | null;
  control_refused_date: string | null;
  measure_refused_by: string | null;
  measure_refused_date: string | null;
  estimate_hours: string;
  contract_reference: string | null;
  contract_id: number;
  parent_activity_id: number | null;
  percent_complete: string;
  code: string;
  predecessor: string | null;
  sub_discipline_id: number;
  project_information_id: number;
  unit_id: number | null;
  percentual: string | null;
  cost: string | null;
  quantity: string | null;
  project_name?: any;
  status_activity_name?: any;
  percentual_name?: any;
  etapa?: any;
  joined_wp_description?: any;
  activity_date?: any;
  old_percentage_value?: any;
  difference: any;
  percentage: any;
  initial_requested_percentage?: string | null;
  rejected_cost?: number | null
}

export const columns: TableColumnsType<DataType> = [
  {
    title: "Detalhes da Atividade",
    key: "activity_details",
    render: (text, record) => {
      // console.log(record.activity?.project_information?.name, record)
      return (
        <div>
          <div>
            <small>
              {record.measurement?.execution_date
                ? new Date(record.measurement.execution_date).toLocaleDateString('pt-BR', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })
                : "-"}
              <br />
            </small>

            <Tag color="purple">{record.activity?.project_information?.name || "-"}</Tag>
            <Tag color="purple">{record.activity?.percentual_group?.name || "-"}</Tag>
            <Tag color="purple">{record.activity?.percentual_group?.category || "-"}</Tag>
            <Tag color="purple">{record.activity?.name || "-"}</Tag>
          </div>
          <div style={{ marginTop: "8px" }}>
            <TextCropToltip
              text={
                record.activity?.cwp?.description ||
                record.activity?.iwp?.description ||
                "-"
              }
              max_quantity={100}
            />
          </div>
        </div>
      );
    },
    width: 450,
  },
  {
    title: "Código",
    dataIndex: ["activity", "code"],
    key: "code",
    width: 250,
    render: (code, record) => (
      <a href={`/activity/${record.activity?.id}`} rel="noopener noreferrer">
        {code}
      </a>
    ),
  },
  {
    title: "Status",
    dataIndex: ["status"],
    key: "status",
    width: 150,

  },
  {
    title: "Justificativa",
    dataIndex: ["measurement", "reason_for_refusal"],
    key: "reason_for_refusal",
    render: (text) => <div>{text || "-"}</div>,
    width: 150,
  },
  {
    title: "Valor Solicitado",
    dataIndex: ["activity", "cost"],
    key: "activity_cost",
    render: (name) => <div>{name ? formatCurrency(name) : "-"}</div>,
    width: 150,
  },
  {
    title: "Porcentagem acumulada da medição",
    dataIndex: ["cumulative_measurement_percentage"],
    key: "percentage",
    render: (percentage) => (
      <div>{percentage !== null ? formatPercentage(percentage) : "-"}</div>
    ),
    width: 200,
  },

  {
    title: "Porcentagem solicitada",
    dataIndex: ["measurement", "initial_requested_percentage"],
    key: "initial_requested_percentage",
    render: (name, record) => (
      <div>
        {name
          ? formatPercentage(name)
          : formatPercentage(record?.measurement?.percentage ?? 0)}
      </div>
    ),
    width: 200,
  },
  


  {
    title: "Porcentagem validada",
    dataIndex: "measurement",
    key: "approved_percentage",
    render: (text, record) => (
      <div>
        {!record.measurement?.measure_refused_by || record.measurement?.status_id === 4
          ? formatPercentage(record.measurement?.percentage ?? 0)
          : "-"}
      </div>
    ),
    width: 200,
  },
  {
    title: "Porcentagem Glosada",
    dataIndex: "glossed_percentage",
    key: "old_percentage_value",
    render: (name, record) => (
      <div>
        {record.glossed_percentage !== undefined
          ? formatPercentage(record.glossed_percentage)
          : ""}
      </div>
    ),
    width: 200,
  },
  {
    title: "Valor Glosado",
    dataIndex: "value_refused",
    key: "refused_percentage_cost",
    render: (name, record) => (
      <div>
        {record.value_refused !== null && record.value_refused !== undefined
          ? formatCurrency(record.value_refused)
          : "-"}
      </div>
    ),
    width: 150,
  },

  {
    title: "Valor recusado",
    dataIndex: "rejected_cost",
    key: "rejected_cost",
    render: (name) => (
      <div style={{ color: "#a90202", fontWeight: "bold" }}>
        {name ? formatCurrency(name) : "-"}
      </div>
    ),
    width: 150,
  },
  {
      title: "Valor validado",
      dataIndex: "cost",
      key: "cost",
      render: (name) => <div style={{ color: "#60a902", fontWeight: "bold" }}>{name ? formatCurrency(name) : "-"}</div>,
      width: 150,
  },

  {
    title: "Valor validado",
    dataIndex: "value_aproved",
    key: "value_aproved",
    render: (value_aproved) => (
      <div style={{ color: "#60a902", fontWeight: "bold" }}>
        {value_aproved ? formatCurrency(value_aproved) : "-"}
      </div>
    ),
    width: 150,
  },
  {
    title: "Porcentagem para Pagamento",
    dataIndex: "percentage_for_payment",
    key: "percentage_for_payment",
    render: (percentage_for_payment) => (
      <div style={{ color: "#60a902", fontWeight: "bold" }}>
        {percentage_for_payment ? percentage_for_payment + '%' : "-"}
      </div>
    ),
    width: 150,
  },
  {
    title: "Justificativa Fiscal",
    dataIndex: "auditor_reason",
    key: "auditor_reason",
    width: 200,
  },
  {
    title: "Status Fiscal",
    dataIndex: "actions",
    key: "actions",
    width: 160,
  },
];

export default columns;
