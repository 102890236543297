import { Tooltip } from "antd";
import { formatCurrency } from "../../../utils/formatCurrency";
import formatDate from "../../../utils/formatDate";
import { formatDateISO } from "../../../utils/formatDateISO";
import { formatPercentage } from "../../../utils/formatPercentage";
import returnStatusMeasure from "../../../utils/returnStatusMeasure";
import returnStatusMeasurementPartials from "../../../utils/returnStatusMeasurementPartials";
import { TextCropToltip } from "../../TextCropToltip";


export const partialColumns = [
  {   title: "Avanço acumulado", dataIndex: "percentage", render: (percentage: any, record: any) => (   <>  {record.solicitation_status_id === 3  ? "0%" : percentage  ? formatPercentage(percentage) : "-"}
      </>
    ),
  },  { 
    title: "Avanço validado", 
    dataIndex: "difference", 
    key: "difference",
    render: (percentage: any) => <>{
      percentage ? formatPercentage(percentage) : "-"}</> 
    },
  { title: "Avanço Solicitado", dataIndex: "initial_requested_percentage", render: (initial_requested_percentage: any) => <>{
    initial_requested_percentage ? formatPercentage(initial_requested_percentage) : "-"}
    </> 
  },
  { title: "Valor de avanço", dataIndex: "cost_percentage_value", render: (value: any) => <>{value ? formatCurrency(value) : "-"}</> },
  { title: "Status", dataIndex: "solicitation_status_id", render: (status: any) => <>{returnStatusMeasurementPartials(status)}</> },
  { title: "Data", dataIndex: "execution_date", render: (date: any) => <>{formatDateISO(date)}</> },
  { title: "Data de Validação", dataIndex: "approved_date", render: (date: any) => <>{date ? formatDate(date) : "-"}</> },
  { title: "Data de Reprovação", dataIndex: "reproved_date", render: (date: any) => <>{date ? formatDate(date) : "-"}</> },
  { title: "Justificativa", dataIndex: "justification" },

  { title: "", dataIndex: "actions" },
];

export const measurementsColumns = [
  { title: "Descrição", dataIndex: "description" },
  { title: "Avanço", dataIndex: "difference", render: (difference: any) => <>{formatPercentage(difference)}</> },
  { title: "Valor do avanço", dataIndex: "cost_percentage_value", render: (difference: any) => <>{formatCurrency(difference)}</> },
  { title: "Status", dataIndex: "status_id", render: (status: any) => <>{returnStatusMeasure(status)}</> },
  { title: "Data de execução", dataIndex: "execution_date", render: (date: any) => <>{date ? formatDateISO(date) : "-"}</> },
  { title: "Razão da recusa", dataIndex: "reason_for_refusal", render: (reason: any) => <TextCropToltip text={reason} max_quantity={15} /> },

  { title: "", dataIndex: "actions" },
];
