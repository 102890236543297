import { useState } from "react";
import api from "../../config/axios";
import { toastAlert } from "../../utils/toastAlert";

type Props = {
  id: number;
  refreshList: VoidFunction;
  message?: string;
};

export const useAproveRefuseFile = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [refresh, setRefresh] = useState<Date>();

  const handleAprove = async ({ id, refreshList }: Props) => {
    setIsFetching(true);

    await api
      .patch(`/v1/activity-upload/${id}/aprove`)
      .then((response) => {
        refreshList();
        setRefresh(new Date());
        toastAlert({
          type: "success",
          message: `Evidência foi validada com sucesso.`,
          title: "Validada com sucesso",
        });
      })
      .catch((error) => {
        toastAlert({
          type: "error",
          message:
            error?.response?.data?.message ||
            `Verifique sua conexão com a internet para tentar novamente.`,
          title: "Erro ao validar",
        });
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const handleRefuse = async ({ id, refreshList, message }: Props) => {
    setIsFetching(true);

    await api
      .patch(`/v1/activity-upload/${id}/refuse`, { message: message })
      .then((response) => {
        refreshList();
        setRefresh(new Date());
        toastAlert({
          type: "success",
          message: `Evidência foi reprovada com sucesso.`,
          title: "Reprovada com sucesso",
        });
      })
      .catch((error) => {
        toastAlert({
          type: "error",
          message:
            error?.response?.data?.message ||
            `Verifique sua conexão com a internet para tentar novamente.`,
          title: "Erro ao reprovar",
        });
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const handleRefuseAfterApprove = async ({
    id,
    refreshList,
    message,
  }: Props) => {
    setIsFetching(true);

    await api
      .patch(`/v1/activity-upload/${id}/refuse-last-aproved`, {
        message: message,
      })
      .then((response) => {
        refreshList();
        setRefresh(new Date());
        toastAlert({
          type: "success",
          message: `Evidência foi reprovada com sucesso.`,
          title: "Reprovada com sucesso",
        });
      })
      .catch((error) => {
        toastAlert({
          type: "error",
          message:
            error?.response?.data?.message ||
            `Verifique sua conexão com a internet para tentar novamente.`,
          title: "Erro ao reprovar",
        });
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  return {
    handleAprove,
    handleRefuse,
    handleRefuseAfterApprove,
    isFetching,
    refresh,
  };
};
