import { Button, Col, Pagination, Row, Table, Typography } from "antd";
import "./measurementBulletinsPage.scss";
import Layout from "../../components/Template/Layout";
import CollapseCard from "../../components/Template/Card";
import ReportSelect from "../ReportPage/components/ReportSelect";
import { useFetchReport } from "../../hooks/report/useFetchReport";

import { useFetchCwas } from "./utils/useFetchCwas";

import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { PaginationProps } from "antd/lib/pagination";
import { useParams } from "react-router-dom";
import columns from "./utils/columns";
import { MdOutlineFolderZip } from "react-icons/md";

import DatePickerComponent from "../../components/DatePicker/DatePicker";
import { useExportBM } from "../../hooks/measure/useExportBM";
import { BsFiletypeXlsx } from "react-icons/bs";
import { formatCurrency } from "../../utils/formatCurrency";
import BulletinActions from "./components/BulletinActions/BulletinActions";
import { MeasurementBulletinActivity } from "../../models/MeasurementBulletinActivity";
import { LuClipboardCheck } from "react-icons/lu";
import { useExportReportEvidence } from "../../hooks/measure/useExportReportEvidence";
import { useApproveAllActivities } from "../../hooks/bulletinActivities/useApproveAllActivities";
import api from "../../config/axios";
import { useFetchReportTotals } from "./utils/useFetchReportTotals";
const { Title } = Typography;

export default function MeasurementBulletinsPage() {
  const [current, setCurrent] = useState(1);
  const [date, setDate] = useState<Date[]>([]);
  const { bulletin_id } = useParams();
  const [project, setProject] = useState("");
  const [refetch, setRefetch] = useState<Date>();

  const [hired, setHired] = useState("");
  const [contract, setContract] = useState("");
  const [roles, setRoles] = useState("");
  const canEdit = roles.includes("Subowner_auditor") || roles.includes("Administrador") || roles.includes("Owner") || roles.includes("Subowner");
  useEffect(() => {
    api.get(`/v1/user/roles`).then((response) => {
      if (response.status === 200) {
        const data = response.data;
        setRoles(data.roles);
      }
    });
  }, []);

  const handleChangeHired = (e: string) => {
    setHired(e);
  };
  const handleChangeContract = (e: string) => {
    setContract(e);
  };

  const handleChangeProject = (e: string) => {
    setProject(e);
  };
  const onChange: PaginationProps["onChange"] = (page) => {
    setCurrent(page);
  };
  const startDate = date?.length > 0 ? dayjs(date[0])?.format("YYYY-MM-DD") : "";
  const endDate = date?.length > 0 ? dayjs(date[1])?.format("YYYY-MM-DD") : "";

  const { cwas } = useFetchCwas();

  const { reportTotals } = useFetchReportTotals(bulletin_id ?? null);

  const { report, isFetching, total, projects, hireds, contracts } = useFetchReport({
    page: current,
    startDate: startDate,
    endDate: endDate,
    project: project,
    cwa: "",
    status: "",
    hired: hired,
    contract: contract,
    bulletins: true,
    bulletin_id: bulletin_id,
    refresh: refetch,
  });
  const exportBm = useExportBM({ id: bulletin_id });

  const handleExport = () => {
    exportBm({
      startDate: startDate,
      endDate: endDate,
      project: project,
      hired: hired,
      contract: contract,
    });
  };
  const { approveAllActivities } = useApproveAllActivities({
    refetch: () => {
      setRefetch(new Date());
    },
  });

  const exportReportEvidence = useExportReportEvidence({ bulletin_id });

  return (
    <Layout pageTitle="Boletim" breadcrumb="Home ⟶ Boletins de medição ⟶ Boletim">
      <Row gutter={16} className="page-row">
        <Col xs={24} md={6} className="mb-2">
          <CollapseCard title="Total">
            <Title level={2} className="total-card">
              {total ? total : 0}
            </Title>
            <br />
          </CollapseCard>
        </Col>

        <Col xs={24} md={18} className="mb-2">
          <CollapseCard title="Filtros">
            <Title level={2} className="total-card">
              <div className="selects-container">
                <div className="container-report-select">
                  <div>
                    <ReportSelect
                      label="Selecione o projeto"
                      handleChange={handleChangeProject}
                      values={projects?.map((project: any) => {
                        return { value: project.id, label: project.name };
                      })}
                    />
                  </div>

                  <div>
                    <ReportSelect
                      label="Selecione o contrato"
                      handleChange={handleChangeContract}
                      values={contracts?.map((contract: any) => {
                        return {
                          value: contract.id,
                          label: contract.description,
                        };
                      })}
                    />
                  </div>
                  <div className="date-container">
                    <DatePickerComponent setValue={setDate} label={"Filtre por Data"} />
                  </div>
                  <div>
                    <ReportSelect
                      label="Selecione o contratado"
                      handleChange={handleChangeHired}
                      values={hireds?.map((hired: any) => {
                        return {
                          value: hired.id,
                          label: hired.name,
                        };
                      })}
                    />
                  </div>

                  <div className="container-buttons-report">
                    <div className="container-buttons-actions">
                      <Button onClick={handleExport} className="button-export">
                        <BsFiletypeXlsx size={24} />
                        Exportar
                      </Button>

                      <Button onClick={exportReportEvidence} className="button-export">
                        <MdOutlineFolderZip size={24} />
                        Download do relatório de evidências
                      </Button>
                    </div>
                    {canEdit && (
                      <Button
                        onClick={() => {
                          approveAllActivities(bulletin_id);
                        }}
                        className="button-export"
                      >
                        <LuClipboardCheck size={24} />
                        Validar todo Boletim
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Title>
          </CollapseCard>
        </Col>
        <Col xs={24} md={24}>
          <CollapseCard title="Atividades" className="card-table-no-padding">
            <Row>
              <div className="table-report-container">
                <Table
                  className="table-report"
                  columns={columns as any}
                  dataSource={report?.map((obj: MeasurementBulletinActivity) => ({
                    ...obj,
                    actions: (
                      <div
                        style={{
                          display: "flex",
                          gap: 8,
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        <BulletinActions
                          data={obj}
                          refetch={() => {
                            setRefetch(new Date());
                          }}
                          roles={roles}
                        />
                      </div>
                    ),
                  }))}
                  pagination={false}
                  loading={isFetching}
                  size="large"
                  scroll={{ x: 4000 }}
                  locale={{ emptyText: "Sem dados" }}
                />
              </div>

              <Col
                span={24}
                className="text-center mt-4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "5px",
                }}
              >
                <Pagination current={current} onChange={onChange} total={total} pageSize={100} showSizeChanger={false} />
              </Col>
              <div className="container-totals-bulletin">
                {!!reportTotals?.total_presenter && (
                  <div className="totals-BM">
                    <span>Total apresentado:</span>
                    <span>{formatCurrency(reportTotals?.total_presenter)}</span>
                  </div>
                )}
                {!!reportTotals?.total_approved && (
                  <div className="totals-BM-approved">
                    <span>Total validado:</span>
                    <span>{formatCurrency(reportTotals?.total_approved)}</span>
                  </div>
                )}
                {!!reportTotals?.total_reproved && (
                  <div className="totals-BM-reproved">
                    <span>Total reprovado:</span>
                    <span>{formatCurrency(reportTotals?.total_reproved)}</span>
                  </div>
                )}
              </div>
            </Row>
          </CollapseCard>
        </Col>
      </Row>
    </Layout>
  );
}
