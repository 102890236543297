import { useState } from "react";
import { Col, Row, Upload, Button, List, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import Layout from "../../components/Template/Layout";
import CollapseCard from "../../components/Template/Card";
import { toastAlert } from "../../utils/toastAlert"; 
import api from "../../config/axios";
import axios from "axios";

export default function UploadPage() {
    const [fileList, setFileList] = useState<File[]>([]);
    const [loading, setLoading] = useState(false);

    // Função para validar e adicionar arquivos .XER
    const handleFileChange = (info: any) => {
        const newFiles: File[] = info.fileList
            .map((file: { originFileObj: File }) => file.originFileObj)
            .filter((file: File) => file.name.toLowerCase().endsWith(".xer"));

        if (newFiles.length === 0) {
            message.error("Apenas arquivos .XER são permitidos.");
            return;
        }

        setFileList(newFiles);
    };

    // Enviar arquivos para o backend
    const handleUpload = async () => {
        if (fileList.length === 0) {
            message.warning("Selecione pelo menos um arquivo .XER.");
            return;
        }
    
        const formData = new FormData();
        fileList.forEach((file) => formData.append("file", file));
    
        try {
            setLoading(true);
            const response = await api.post("/v1/files/upload/xer-files", formData, {
                headers: { "Content-Type": "multipart/form-data" },
                responseType: "blob", // ⚡ Indica que a resposta é um arquivo
            });
    
            // Criando o blob a partir da resposta
            const blob = new Blob([response.data], { type: "text/csv" });
            const url = window.URL.createObjectURL(blob);
    
            // Criando um link temporário para baixar o arquivo
            const a = document.createElement("a");
            a.href = url;
            a.download = "exportacao_xer.csv"; // Nome do arquivo baixado
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
    
            // Mostra toast de sucesso
            toastAlert({ type: "success", message: "Arquivo baixado com sucesso!", title: "Sucesso" });
    
            setFileList([]); // Limpa a lista após o upload
        } catch (error) {
            let errorMessage = "Erro ao enviar arquivos!";
            if (axios.isAxiosError(error)) {
                errorMessage = error.response?.data?.message || error.message;
            }
            toastAlert({ type: "error", message: errorMessage, title: "Erro" });
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <Layout pageTitle="Upload" breadcrumb="Home ⟶ Upload de Arquivos .XER">
            <Row gutter={16} className="page-row">
                <Col xs={24} md={24} className="mb-2">
                    <CollapseCard title="Upload de Arquivos .XER">
                        {/* Área de arrastar e soltar */}
                        <Upload.Dragger
                            multiple
                            beforeUpload={() => false}
                            onChange={handleFileChange}
                            accept=".xer"
                            fileList={fileList.map((file) => ({
                                uid: file.name,
                                name: file.name,
                                status: "done",
                            }))}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined style={{ fontSize: "2rem" }} />
                            </p>
                            <p className="ant-upload-text">Arraste e solte seus arquivos .XER aqui</p>
                            <p className="ant-upload-hint">Ou clique para selecionar arquivos</p>
                        </Upload.Dragger>

                        {/* Lista dos arquivos apenas com nome e tamanho */}
                        {fileList.length > 0 && (
                            <List
                                bordered
                                dataSource={fileList}
                                renderItem={(file) => (
                                    <List.Item>
                                        <strong>{file.name}</strong> - {(file.size / 1024).toFixed(2)} KB
                                    </List.Item>
                                )}
                                style={{ marginTop: "1rem" }}
                            />
                        )}

                        {/* Botão de envio */}
                        <Button
                            type="primary"
                            onClick={handleUpload}
                            loading={loading}
                            disabled={fileList.length === 0}
                            style={{ marginTop: "1rem" }}
                        >
                            {loading ? "Enviando..." : "Enviar Arquivos"}
                        </Button>
                    </CollapseCard>
                </Col>
            </Row>
        </Layout>
    );
}
