import { Badge } from "antd";

export const tabs = [
  {
    label: <Badge status="default" text="Pendentes" />,
    key: "1",
    children: <></>,
  },
  {
    label: <Badge status="success" text="Validadas" />,
    key: "4",
    children: <></>,
  },
  {
    label: <Badge status="error" text="Recusadas" />,
    key: "3",
    children: <></>,
  },
];
