import { Popconfirm, Tooltip } from "antd";
import { ReactNode, useEffect, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import { IoMdDownload } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { RiArrowGoBackFill } from "react-icons/ri";
import { useAproveRefuseFile } from "../../hooks/files/useAproveRefuseFile";
import { useDeleteFile } from "../../hooks/files/useDeleteFile";
import { useDownloadFile } from "../../hooks/files/useDownloadFile";
import "./fileList.scss";
import RefuseModal from "./modalRefuse/modal";
import RefuseAfterApproveModal from "./modalRefuseAfterApprove/modal";
import PopConfirmIcon from "../PopConfirmIcon/PopConfirmIcon";
import { AiOutlineEye } from "react-icons/ai";

export function MeasureFileListActions({
  file,
  refreshList,
  setTableIsLoading,
  roles,
  hideActions,
  disableApprove,
  disableRefuse,
  alwaysShowRefuseAfterApprove,
}: {
  file: any;
  refreshList: VoidFunction;
  setTableIsLoading: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  roles: string[];
  hideActions: boolean;
  disableApprove?: boolean;
  disableRefuse?: boolean;
  alwaysShowRefuseAfterApprove?: boolean;
}) {
  const { handleDelete, isFetching: isFetchingDelete } = useDeleteFile();
  const { handleAprove, isFetching: isFetchingAproveRefuse } = useAproveRefuseFile();
  const { handleDownload, isFetching: isFetchingDownload } = useDownloadFile();
  const [refuseModalOpen, setRefuseModalOpen] = useState(false);
  const [refuseAfterApproveModalOpen, setrefuseAfterApproveModalOpen] = useState(false);

  const userCanAprove = () => {
    if (roles.length === 0) return false;
    else if (["Administrador", "Owner"].some((item) => roles[0].includes(item))) return true;
    else if (roles[0].includes("Subowner")) {
      let currentStep;

      if (!!file?.control_approved_by && file?.requires_control) return false;
      else if (!!file?.quality_approved_by && file?.requires_control) currentStep = "control";
      else if (file?.requires_quality && !file?.quality_approved_by) currentStep = "quality";
      else if (file?.requires_control && !file?.control_approved_by) currentStep = "control";
      else return false;

      return roles[0].includes("Subowner_" + currentStep);
    } else return false;
  };
  const canDelete = () => {
    if (["Administrador", "Owner"].some((item) => roles[0].includes(item))) return true;
    else if (file.user_id === localStorage.getItem("user_id")) return true;
    return false;
  };

  useEffect(() => {
    if (isFetchingDelete || isFetchingAproveRefuse || isFetchingDownload) {
      setTableIsLoading(true);
    } else if (!isFetchingDelete && !isFetchingAproveRefuse && !isFetchingDownload) {
      setTableIsLoading(false);
    }
  }, [isFetchingDelete, isFetchingAproveRefuse, isFetchingDownload, setTableIsLoading]);

  const aproveIsDisabled = () => !!file.control_approved_by || !userCanAprove() || !!disableApprove;

  const refuseIsDisabled = () => {
    if (!!disableRefuse) return true;

    if (!userCanAprove()) return true;

    if (!file?.control_approved_by && !!file?.control_refused_by) {
      return true;
    } else if (!file?.quality_approved_by && !!file?.quality_refused_by) {
      return true;
    } else return !!file?.control_approved_by;
  };

  const enableRefuseAfterApproveIcon = () => {
    const userCanRefuse = (step: string) =>
      ["Administrador", "Owner"].some((item) => roles[0].includes(item)) || roles[0].includes("Subowner_" + step);

    if (!!file?.control_approved_by && !!file?.control_refused_by) {
      return true;
    } else if (!file?.control_approved_by && !file?.control_refused_by && !!file?.quality_approved_by && userCanRefuse("quality")) {
      return true;
    } else {
      return false;
    }
  };

  return roles.length > 0 ? (
    <div className="file-table-list-actions">
      <>
        <PopConfirmIcon
          title="Validar"
          description="Tem certeza que deseja validar essa evidência?"
          disabled={aproveIsDisabled()}
          onConfirm={() => !aproveIsDisabled() && !isFetchingDelete && handleAprove({ id: file.upload_id, refreshList })}
          icon={<FaCheck size={18} className={aproveIsDisabled() ? "file-table-list-actions-disabled" : ""} />}
        />
        <div>
          <RefuseModal id={file.upload_id} refreshList={refreshList} handleClose={() => setRefuseModalOpen(false)} isModalOpen={refuseModalOpen} />
          <PopConfirmIcon
            title="Reprovar"
            description="Tem certeza que deseja reprovar essa evidência?"
            onConfirm={() => !refuseIsDisabled() && !isFetchingDelete && setRefuseModalOpen(true)}
            disabled={refuseIsDisabled()}
            icon={<IoClose size={20} className={refuseIsDisabled() ? "file-table-list-actions-disabled" : ""} />}
          />
        </div>
      </>

      <Tooltip title="Download">
        <IoMdDownload size={20} onClick={() => !isFetchingDelete && handleDownload(file.upload_id)} />
      </Tooltip>
      <Tooltip title="Visualizar">
        <AiOutlineEye   size={20}    onClick={() => !isFetchingDelete && handleDownload(file.upload_id, true)}          />
      </Tooltip>
      {canDelete() ? (
        <PopConfirmIcon
          title="Deletar"
          description="Tem certeza que deseja deletar essa evidência?"
          onConfirm={(setConfirmIsOpen) =>
            !isFetchingDelete &&
            handleDelete({
              id: file.upload_id,
              refreshList,
              isOpen: () => {
                setConfirmIsOpen(false);
              },
            })
          }
          disabled={isFetchingAproveRefuse}
          icon={<FaTrashAlt size={18} color="red" />}
        />
      ) : null}

      {enableRefuseAfterApproveIcon() || alwaysShowRefuseAfterApprove ? (
        <div>
          <RefuseAfterApproveModal
            id={file.upload_id}
            refreshList={refreshList}
            handleClose={() => setrefuseAfterApproveModalOpen(false)}
            isModalOpen={refuseAfterApproveModalOpen}
          />
          <PopConfirmIcon
            title={enableRefuseAfterApproveIcon() ? "Reprovar última etapa validada" : "Evidência está em outra etapa e não pode ser reprovada nessa"}
            description="Tem certeza que deseja reprovar essa evidência na etapa que já foi validada?"
            onConfirm={() => !isFetchingDelete && setrefuseAfterApproveModalOpen(true)}
            disabled={!enableRefuseAfterApproveIcon()}
            icon={<RiArrowGoBackFill size={18} className={!enableRefuseAfterApproveIcon() ? "file-table-list-actions-disabled" : ""} />}
          />
        </div>
      ) : null}
    </div>
  ) : (
    <></>
  );
}
