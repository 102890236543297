import { Button, Modal, RadioChangeEvent } from "antd";

import { useState } from "react";
import RadioButtons from "../../RadioButtons/RadioButtons";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  submit: (value: number) => void;
  actual_percentage: any;
  activity: any;
  
};

export default function ModalNewPercentage({ isOpen, handleClose, submit, activity, actual_percentage }: Props) {
  const [value, setValue] = useState(0);
  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };
  const partials_measurements = activity.partials_measurements;

  const radioOptions = activity.dynamic_measurement_items?.map((item: any) => ({
    value: parseFloat(item.percentage), 
    name: `${item.name}: aplica-se avanço de ${item.percentage}%`,
  })) || [];

  return (
    <Modal
      title={`Mudar porcentagem de medição`}
      footer={[
        <Button key="back" onClick={handleClose}>
          Cancelar
        </Button>,
        <Button key="submit" disabled={value === 0} onClick={() => submit(value)} type="primary" danger>
          Editar e validar
        </Button>,
      ]}
      width={"50%"}
      open={isOpen}
      onCancel={handleClose}
    >
      <div className="container-radio mt-2">
        <span>Selecione a opção desejada:</span>
        <RadioButtons
          onChange={onChange}
          value={value}
          options={radioOptions}
          executedValue={actual_percentage}
          partials_measurements={partials_measurements}
        />
      </div>
    </Modal>
  );
}
