import { Col, Menu, Row, Layout as Menus, } from "antd";
import Layout from "../../components/Template/Layout";
import CollapseCard from "../../components/Template/Card";

import { Outlet, useNavigate } from "react-router-dom";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import { useEffect } from "react";


export default function SettingsPage() {
  const navigate = useNavigate();

  const handleMenuClick = ({ key }: { key: string }) => {
    navigate(key);
  };
  useEffect(() => {
    navigate("/settings/iwp-flag");
  }, [navigate]);

  const menuItems = [
    {
      key: "/settings/iwp-flag",
      label: "Configuração de IWPs",
    },
    {
      key: "/settings/measurement-validation",
      label: "Validações de medição",
    },
  ];
  return (
    <Layout pageTitle="Configurações" breadcrumb="Home ⟶ Configurações">
      <Row gutter={16} className="page-row">
        <Col xs={24} md={24} className="mb-2">
          <CollapseCard title="Menus de Configurações">
            <Menus style={{ minHeight: "50vh" }}>
              <Sider width={200} style={{ background: "#fff" }}>
                <Menu
                  mode="inline"
                  defaultSelectedKeys={["/settings/iwp-flag"]}
                  style={{ height: "100%", borderRight: 0 }}
                  onClick={handleMenuClick}
                  items={menuItems}
                />
              </Sider>
              <Menus style={{ padding: "24px" }}>
                <Content style={{ background: "#fff", padding: 24, margin: 0 }}>
                  <Outlet /> {/* Renderiza as rotas filhas */}
                </Content>
              </Menus>
            </Menus>
          </CollapseCard>
        </Col>
      </Row>
    </Layout>
  )
}
