import { Modal, Form, Input, Button, Space, Row, Col, Card } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import api from "../../config/axios";
import { toastAlert } from "../../utils/toastAlert";
import './MeasurementValidationModal.scss'
import axios from "axios";

interface MeasurementValidationModalProps {
    open: boolean;
    onClose: () => void;
    onSuccess: () => void;
}

export default function MeasurementValidationModal({ open, onClose, onSuccess }: MeasurementValidationModalProps) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const handleSave = async () => {
        try {
            const values = await form.validateFields();
            setLoading(true);

            // Envia o nome da regra junto com os itens
            await api.post("/v1/measure/create-validation", values);

            toastAlert({ type: "success", message: "Regra criada com sucesso!", title: "Sucesso" });
            form.resetFields();
            onSuccess();
            onClose(); 

        } catch (error) {
            let errorMessage = "Erro ao criar regra!";

            // Se for um erro do Axios e tiver resposta da API, exibir a mensagem correta
            if (axios.isAxiosError(error)) {
                errorMessage = error.response?.data?.message || error.message;
            }
            toastAlert({ type: "error", message: errorMessage, title: "Erro" });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            title="Adicionar Nova Regra de Medição"
            open={open}
            onCancel={onClose}
            footer={null}
            width={800}
        >

            <Card size="small" style={{ marginBottom: 16, backgroundColor: "#fafafa", borderLeft: "4px solid #1890ff" }}>
                <p><strong>Observação:</strong> Insira os itens de medição com base nesse formato:</p>
                <ul>
                    <li><strong>Nome:</strong> "50,01% a 74,99%"</li>
                    <li><strong>Inicial (%):</strong> 50.01</li>
                    <li><strong>Final (%):</strong> 74.99</li>
                    <li><strong>Porcentagem:</strong> 50.00</li>
                </ul>
                <p>Exemplo de outras faixas:</p>
                <ul>
                    <li><strong>75% a 89,99%:</strong> Inicial: 75.00 | Final: 89.99 | Porcentagem: 75.00</li>
                    <li><strong>90 a 99,99%:</strong> Inicial: 90.00 | Final: 99.99 | Porcentagem: 90.00</li>
                    <li><strong>100%:</strong> Inicial: 100.00 | Final: 100.00 | Porcentagem: 100.00</li>
                </ul>
            </Card>
            <div className="form-content">
                <Form form={form} layout="vertical">
                    <div className="formOne">
                        <Form.Item
                            label="Nome da Regra"
                            name="nome"
                            rules={[{ required: true, message: "O nome é obrigatório!" }]}
                        >
                            <Input placeholder="Digite o nome da regra" />
                        </Form.Item>
                    </div>

                    <div className="formOne">
                        <Form.List name="items">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <div className="formOne">
                                            <Row key={key} gutter={8} align="middle">
                                                <Col span={6}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "name"]}
                                                        rules={[{ required: true, message: "Nome é obrigatório!" }]}
                                                    >
                                                        <Input placeholder="Nome" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={5}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "initial_percentage"]}
                                                        rules={[{ required: true, message: "Obrigatório!" }]}
                                                    >
                                                        <Input placeholder="Inicial (%)" type="number" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={5}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "end_percentage"]}
                                                        rules={[{ required: true, message: "Obrigatório!" }]}
                                                    >
                                                        <Input placeholder="Final (%)" type="number" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={5}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "percentage"]}
                                                        rules={[{ required: true, message: "Obrigatório!" }]}
                                                    >
                                                        <Input placeholder="Porcentagem" type="number" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={3}>
                                                    <MinusCircleOutlined onClick={() => remove(name)} style={{ color: "red" }} />
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Adicionar Item
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end", gap: 8 }}>
                        <Button onClick={onClose}>Cancelar</Button>
                        <Button type="primary" onClick={handleSave} loading={loading}>
                            Salvar
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
}
