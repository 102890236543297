import { Card, Col, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ActivityView from "../../components/ActivityView";
import Layout from "../../components/Template/Layout";
import api from "../../services/Api";
import "./activityViewPage.scss";

function ActivityViewPage() {
  const { activity_id } = useParams();
  const { state } = useLocation();

  const [refreshActivity, setRefreshActivity] = useState<Date>(new Date());
  const [activity, setActivity] = useState<any | undefined>();
  const [projectName, setProjectName] = useState(undefined);
  const [breadcrumb, setBreadcrumb] = useState("");

  useEffect(() => {
    api.get("/v1/activity/" + activity_id).then((response) => {
      if (response?.status === 200) {
        const data = response.data;     
        const info = data.information;
        const iwp = info.type === "IWP" ? ` ⟶ ${info.joined_wp_name}` : "";

        const menu = `${info.project} ⟶ CWA-${info.cwa_code} ⟶ ${info.wp_name} ${iwp} ${
          info.percentual_group_category ? ` ⟶ ${info.percentual_group_category}` : ""
        } ⟶ ${info.name} `;
        setBreadcrumb(menu);
        setActivity(data);
      }
    });
  }, [activity_id, refreshActivity]);

  useEffect(() => {
    if (activity) {
      api.get("/v1/projects/" + activity?.project_information_id).then((response) => {
        const data = response.data;
        setProjectName(data.name);
      });
    }
  }, [activity]);

  return (
    <>
      <Layout pageTitle="Detalhes da Atividade" breadcrumb={breadcrumb}>
        {activity ? (
          <ActivityView hasList={false} activity={activity} setRefreshActivity={setRefreshActivity} />
        ) : (
          <Row className="page-row">
            <Col xs={24} lg={12}>
              <Card>
                <Spin />
              </Card>
            </Col>
          </Row>
        )}
      </Layout>
    </>
  );
}

export default ActivityViewPage;
