import { Modal, Select, Button, Form, message } from "antd";
import { useState } from "react";
import api from "../../config/axios";
import { useFetchContracts } from "../../hooks/contracts/useFetchContracts";

interface IncludeValidationInContractModalProps {
    open: boolean;
    onClose: () => void;
    validation: any;
    onSuccess: () => void;
}

export default function IncludeValidationInContractModal({ open, onClose, validation , onSuccess}: IncludeValidationInContractModalProps) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const { contracts, isFetching } = useFetchContracts({ current: 1 });
    const handleSave = async () => {
        try {
            const values = await form.validateFields();
            setLoading(true);

            await api.post("/v1/measure/assign-validation", {
                validation_id: validation.id,
                contract_id: values.contract_id
            });

            message.success("Regra associada ao contrato com sucesso!");
            form.resetFields();
            onSuccess();
            onClose();
        } catch (error) {
            message.error("Erro ao associar regra ao contrato.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            title="Incluir essa regra no contrato"
            open={open}
            onCancel={onClose}
            footer={null}
            width={500}
        >
            <Form form={form} layout="vertical">
                <div className="formOne">
                <Form.Item
                   
                    name="contract_id"
                    rules={[{ required: true, message: "Selecione um contrato!" }]}
                >
                    <Select placeholder="Escolha um contrato" loading={isFetching}>
                        {contracts.map((contract: any) => (
                            <Select.Option key={contract.id} value={contract.id}>
                                {contract.hired_name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                </div>


                <div style={{ display: "flex", justifyContent: "flex-end", gap: 8 }}>
                    <Button onClick={onClose}>Cancelar</Button>
                    <Button type="primary" onClick={handleSave} loading={loading}>
                        Salvar
                    </Button>
                </div>
            </Form>
        </Modal>
    );
}
