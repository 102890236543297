import "./measurementPartialsActions.scss";
import { Popconfirm, Tooltip } from "antd";
import { FaCheck } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import PopConfirmIcon from "../PopConfirmIcon/PopConfirmIcon";
import useChangeStatusPartial from "../../hooks/measure/useChangeStatusPartial";
import { MeasurementPartial } from "../../models/MeasurementPartial";
import { useEffect, useState } from "react";
import RefuseModal from "../ModalRefuse/ModalRefuse";
import ModalNewPercentage from "./ModalNewPercentage/ModalNewPercentage";
import { useUpdateMeasurement } from "../../hooks/measure/useUpdateMeasurement";
import api from "../../config/axios";

type Props = {
  measurement_partial: MeasurementPartial;
  refreshList: () => void;
  actual_percentage: any;
  activity: any;
};

export default function MeasurementPartialsActions({ measurement_partial, refreshList, activity, actual_percentage }: Props) {
  const partials_measurements = activity.partials_measurements;;
  const { changeStatus, refresh } = useChangeStatusPartial();
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openRadioModal, setOpenRadioModal] = useState(false);
  const [roles, setRoles] = useState("");
  const { updateMeasurement } = useUpdateMeasurement();
  useEffect(() => {
    refreshList();
  }, [refresh]);

  useEffect(() => {
    api.get(`/v1/user/roles`).then((response) => {
      if (response.status === 200) {
        const data = response.data;
        setRoles(data.roles);
      }
    });
  }, []);
  const canEdit = roles.includes("Subowner_control") || roles.includes("Administrador") || roles.includes("Owner") || roles.includes("Subowner");

  const handleRefuse = (message: string) => {
    changeStatus({ action: "reject", message: message, id: measurement_partial.id });
  };

  const rejectDisabled = measurement_partial.solicitation_status_id === 3 || measurement_partial.solicitation_status_id === 4 || measurement_partial.solicitation_status_id === 5;
  const showEdit = canEdit && !rejectDisabled ? true : false ;
  return (
    <div className="file-table-list-actions">
      {showEdit && (
        <>
          <PopConfirmIcon
            title="Validar"
            description="Tem certeza que deseja validar essa solicitação?"
            disabled={rejectDisabled}
            onConfirm={() => {
              changeStatus({ id: measurement_partial.id, action: "approve" });
            }}
            icon={<FaCheck size={18} className={`file-table-list-actions${!!rejectDisabled ? "-disabled" : ""}`} />}
          />
          <div>
            <Popconfirm
              disabled={rejectDisabled}
              title={"Reprovar"}
              description={"Essa atividade pode ser medida em outra proporção?"}
              onConfirm={() => setOpenRadioModal(!openRadioModal)}
              onCancel={() => setOpenModal(true)}
              popupVisible={confirmIsOpen}
              onOpenChange={(open) => setConfirmIsOpen(open)}
              okText="Sim"
              cancelText="Não"
            >
              <Tooltip title={"Reprovar"}>
                <IoClose size={20} className={`file-table-list-actions${!!rejectDisabled ? "-disabled" : ""}`} />
              </Tooltip>
            </Popconfirm>
          </div>
          <RefuseModal
            isModalOpen={openModal}
            handleClose={() => setOpenModal(!openModal)}
            handleRefuse={(message) => {
              handleRefuse(message);
            }}
          />
          <ModalNewPercentage
            actual_percentage={actual_percentage}
            activity={activity}
            isOpen={openRadioModal}
            handleClose={() => setOpenRadioModal(!openRadioModal)}
            submit={(value) => {
              updateMeasurement({ id: measurement_partial.id, percentage: value, new_measurement: true });
              setOpenRadioModal(!openRadioModal);
              refreshList();
            }}
          />
        </>
      )}
    </div>
  );
}
