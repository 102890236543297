import { useMemo, useState } from "react";
import "./style.scss";
import { Badge, Tag, Tooltip } from "antd";

interface StatusList {
  [key: string]: {
    [key: string]: number;
  };
}

const ActivityUploadStatus: React.FC<{ statusList: StatusList }> = ({ statusList }) => {
  const getStatusColor = (status: string): string => {
    switch (status) {
      case "pending":
        return "yellow";
      case "rejected":
        return "red";
      case "approved":
        return "green";
      default:
        return "gray";
    }
  };

  const getStatusName = (name: string): string => {
    switch (name) {
      case "quality":
        return "Qualidade";
      case "control":
        return "Controle";
      default:
        return " ";
    }
  };

  const getStatusName2 = (name: string): string => {
    switch (name) {
      case "pending":
        return "Pendente";
      case "rejected":
        return "Recusado";
      case "approved":
        return "Validado";
      default:
        return "...";
    }
  };

  const [arrow, setArrow] = useState("Show");
  const mergedArrow = useMemo(() => {
    if (arrow === "Hide") {
      return false;
    }

    if (arrow === "Show") {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  return (
    <>
      {Object.keys(statusList).map((category, index) => (
        <fieldset key={index} className="fieldsetValidation">
          <legend>{getStatusName(category)}</legend>
          <div className="flex-center">
            {Object.keys(statusList[category]).map((status, index) => (
              <>
                <div key={index} className="">
                  <Tooltip
                    placement="topRight"
                    title={getStatusName(category) + " " + getStatusName2(status) + ": " + statusList[category][status]}
                    arrow={mergedArrow}
                  >
                    <Tag color={getStatusColor(status)}>{statusList[category][status]}</Tag>
                  </Tooltip>
                </div>
              </>
            ))}
          </div>
        </fieldset>
      ))}
    </>
  );
};

export default ActivityUploadStatus;
