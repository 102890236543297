import "./measurementBulletinHome.scss";
import Layout from "../../components/Template/Layout";
import { Button, Col, Progress, Row, Table, Typography } from "antd";
import CollapseCard from "../../components/Template/Card";
import ModalCreateBm from "./ModalCreateBm/ModalCreateBm";
import { useState } from "react";
import { useFetchBms } from "../../hooks/MeasurementBulletin/useFetchBms";
import { useCreateBm } from "../../hooks/MeasurementBulletin/useCreateBm";
import columns from "./utils/columns";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { formatCurrency } from "../../utils/formatCurrency";
import ReportSelect from "../ReportPage/components/ReportSelect";

const { Title } = Typography;
export default function MeasurementBulletinHome() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { refresh, createBm } = useCreateBm();
  const [contract, setContract] = useState();
  const [project, setProject] = useState();
  const { bms, isFetching, total, totalBms, contracts, projects, totalTable } = useFetchBms({ refresh, contract, project });
  const totalPercent =
    !!totalBms?.total_activities_value && !!totalBms?.total_cost ? +((totalBms?.total_activities_value / totalBms?.total_cost) * 100).toFixed(2) : 0;

  return (
    <>
      <Layout pageTitle="Boletins de medição" breadcrumb="Home ⟶  Boletins de medição">
        <Row gutter={16} className="page-row">
          <Col xs={24} md={6} className="mb-2">
            <CollapseCard title="Total">
              <Title level={2} className="total-card">
              
                {total}
              </Title>
              <br />
            </CollapseCard>
          </Col>

          <Col xs={24} md={18} className="mb-2">
            <CollapseCard title="Ações">
              <Title level={2} className="total-card">
                <div className="selects-container-bm">
                  <ReportSelect
                    values={contracts?.map((item) => {
                      return {
                        label: item.description,
                        value: item.id,
                      };
                    })}
                    label="Contrato"
                    handleChange={(e: any) => setContract(e)}
                  />
                  <ReportSelect
                    values={projects?.map((item) => {
                      return {
                        label: item.description,
                        value: item.id,
                      };
                    })}
                    label="Projeto"
                    handleChange={(e: any) => setProject(e)}
                  />
                  <Button onClick={() => setIsModalOpen(true)} className="button-export">
                    <MdOutlineCalendarMonth size={24} />
                    Gerar Boletim
                  </Button>
                </div>
              </Title>
            </CollapseCard>
          </Col>
        </Row>
        <Col xs={24} md={24}>
          <CollapseCard title="Boletins" className="card-table-no-padding">
            <Row>
              <div className="table-report-container">
                <Table
                  className="table-report"
                  columns={columns}
                  dataSource={bms}
                  pagination={false}
                  loading={isFetching}
                  size="small"
                  locale={{ emptyText: "Sem dados" }}
                />
                <p className="text-right mr-10 pr-3">
                  <strong>Total de BM gerado:</strong>{" "}
                  {totalBms?.total_activities_value > 0 ? formatCurrency(totalBms?.total_activities_value) : "R$ 0,00"}
                </p>
              </div>

              <Col
                span={24}
                className="text-center mt-4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "5px",
                }}
              >
                {/*   <Pagination current={current} onChange={onChange} total={total} pageSize={100} showSizeChanger={false} /> */}
              </Col>
            </Row>
            <div className="total-bms-container">
              <div className="info-bm-container">
                <span>
                  Total Validado: <b>{totalBms?.total_activities_value > 0 ? formatCurrency(totalBms?.total_activities_value) : "R$ 0,00"}</b> /{" "}
                  {totalBms?.total_cost > 0 ? formatCurrency(totalBms?.total_cost) : "R$ 0,00"}
                </span>
                <Progress percent={totalPercent} />
              </div>
            </div>
          </CollapseCard>
        </Col>
      </Layout>
      <ModalCreateBm
        handleClose={() => {
          setIsModalOpen(!isModalOpen);
        }}
        contracts={contracts}
        projects={projects}
        createBm={createBm}
        isModalOpen={isModalOpen}
      />
    </>
  );
}
