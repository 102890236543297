import { useCallback, useEffect, useState } from "react";
import api from "../../config/axios";

export const useFetchValidationActivity = (activityId: string) => {
  const [allValidations, setAllValidations] = useState([]);
  const [selectedValidations, setSelectedValidations] = useState<string[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  // Busca as validações já atribuídas à atividade
  const fetchActivityValidations = useCallback(() => {
    setIsFetching(true);
    api
      .get(`v1/validation-evidence/activity-validations/${activityId}`)
      .then((response) => {
        const existingValidations = response.data.validation_evidences.map(
          (validation: any) => validation.pivot.validation_id // Corrigido para pegar o ID correto
        );
        setSelectedValidations(existingValidations);
      })
      .catch((err) => {
        console.error("Erro ao buscar validações da atividade: ", err);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [activityId]);

  // Busca todas as validações disponíveis
  const fetchAllValidations = useCallback(() => {
    api
      .get("v1/validation-evidence")
      .then((response) => {
        setAllValidations(response.data.data);
      })
      .catch((err) => {
        console.error("Erro ao buscar todas as validações: ", err);
      });
  }, []);

  useEffect(() => {
    if (activityId) {
      fetchActivityValidations();
    }
    fetchAllValidations();
  }, [fetchActivityValidations, fetchAllValidations]);

  return {
    validations: allValidations, // Todas as regras disponíveis
    selectedValidations, // Regras pré-selecionadas
    isFetching,
    setSelectedValidations,
  };
};
