import { Table, Button, Popconfirm } from "antd";
import { useState } from "react";
import { DeleteOutlined, SettingOutlined } from "@ant-design/icons";

import "./SettingsPage.scss";
import { useFetchMeasurementValidations } from "../../hooks/measure/useMeasureValidation";
import MeasurementValidationModal from "../../components/MeasurementValidationModal";
import IncludeValidationInContractModal from "../../components/MeasurementValidationModal/IncludeValidationInContractModal";
import { toastAlert } from "../../utils/toastAlert";
import api from "../../config/axios";

export default function MeasurementValidation() {
    const { validations, loading, fetchValidations } = useFetchMeasurementValidations();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isContractModalOpen, setIsContractModalOpen] = useState(false);
    const [selectedValidation, setSelectedValidation] = useState<any>(null);

    const handleOpenContractModal = (validation: any) => {
        setSelectedValidation(validation);
        setIsContractModalOpen(true);
    };


    const handleDelete = async (id: number) => {
        try {
            await api.delete(`/v1/measure/delete-validation/${id}`);
            toastAlert({ type: "success", message: "Regra excluida com sucesso!", title: "Sucesso" });
            fetchValidations(); // Atualiza a lista após a exclusão
        } catch (error) {
            toastAlert({ type: "error", message: "Erro ao excluir regra!", title: "Erro" });
        }
    };

    // Configuração das colunas da Tabela Principal
    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: 100,
        },
        {
            title: "Nome",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Contrato",
            dataIndex: "contract",
            key: "contract",
            render: (contract: any) => contract?.hired?.name || "Não aplicado em nenhum contrato",
        },

        {
            title: "Ações",
            key: "actions",
            width: 150,
            render: (_: any, record: any) => (
                <div className="content-render">
                    <div >
                        <Button
                            icon={<SettingOutlined />}
                            onClick={() => handleOpenContractModal(record)}
                        />
                    </div>
                    <div className="space">
                        <Popconfirm
                            title="Tem certeza que deseja excluir?"
                            onConfirm={() => handleDelete(record.id)}
                            okText="Sim"
                            cancelText="Não"
                        >
                            <Button icon={<DeleteOutlined />} danger />
                        </Popconfirm>
                    </div>
                </div>
            ),
        }
    ];

    // Configuração das colunas da Tabela Expandida (Itens)
    const expandedRowRender = (record: any) => {
        const subColumns = [
            {
                title: "ID",
                dataIndex: "id",
                key: "id",
                width: 100,
            },
            {
                title: "Nome",
                dataIndex: "name",
                key: "name",
            },
            {
                title: "Inicial (%)",
                dataIndex: "initial_percentage",
                key: "initial_percentage",
                width: 150,
            },
            {
                title: "Final (%)",
                dataIndex: "end_percentage",
                key: "end_percentage",
                width: 150,
            },
            {
                title: "Porcentagem",
                dataIndex: "percentage",
                key: "percentage",
                width: 150,
            }
        ];

        return (
            <Table
                columns={subColumns}
                dataSource={record.items}
                rowKey="id"
                pagination={false}
                bordered
            />
        );
    };

    return (
        <div className="measurement-validation-container">
            <Button
                type="primary"
                onClick={() => setIsModalOpen(true)}
                style={{ marginBottom: 16 }}
            >
                Adicionar Nova Regra de Medição
            </Button>

            <Table
                columns={columns}
                dataSource={validations}
                rowKey="id"
                loading={loading}
                expandable={{ expandedRowRender }}
                bordered
            />

            {/* Modal para adicionar nova regra */}
            <MeasurementValidationModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSuccess={fetchValidations}
            />
            <IncludeValidationInContractModal
                open={isContractModalOpen}
                onClose={() => setIsContractModalOpen(false)}
                validation={selectedValidation}
                onSuccess={fetchValidations}
            />
        </div>
    );
}
